// ====== SELECT FILTER ========


.s-filtros-instituicoes .filtro-geral form .item .inputs {
  display: flex;
}

.select-padrao * {

  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  text-align: left;
  border: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0!important;
  font-family: Roboto,sans-serif;

}
.s-filtros-instituicoes .filtro-geral form .item .inputs .select-padrao.select-regiao {
  width: 300px;
}

.select-padrao.open-select .dropdown .item-escolhido {
  border-bottom: 1px solid rgba(128,128,128,.1);
}

.s-filtros-instituicoes .filtro-geral form .item .inputs .select-padrao {
  width: 154px;
  margin-right: 20px;
}

.select-padrao {
  position: relative;
  width: 100%;
  // min-width: 225px;
}

@media (max-width: 768px) {
  .select-padrao {
    min-width: 0;
  }
}

.select-padrao.open-select .dropdown {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}
.select-padrao .dropdown {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #ddd;
  -webkit-appearance: none;
  font-size: 15px;
  color: #9b9b9b;
  background: #fff;
  position: relative;
  cursor: pointer;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0 17px;
}

.select-padrao .dropdown .item-escolhido {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
}

.select-padrao .dropdown .item-escolhido span {
  font-size: 15px;
  color: #9b9b9b;
}


.select-padrao.open-select .dropdown .item-escolhido img {
  transition: all .3s;
  transform: rotate(180deg);
}

.select-padrao .dropdown .item-escolhido img {
  position: absolute;
  right: 20px;
  transition: all .3s;
  transform: rotate(0);
}

img {
  border: 0;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.select-padrao ul {
  border: solid 1px #ddd;
  border-radius: 0 0 4px 4px;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 10;
  top: 45px;
  pointer-events: none;
  left: 0;
  opacity: 0;
  transition: all .3s;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
}

a:active, a:hover {
  outline: 0;
}

.select-padrao.open-select ul {
  top: 50px;
  pointer-events: all;
  opacity: 1;
  left: 0;
  transition: all .3s;
}

.select-padrao ul li span {
  display: flex;
  height: 61px;
  color: #5a5e5c;
  padding-left: 17px;
  align-items: center;
  transition: all .3s;
  position: relative;
}

a {
  background-color: transparent;
  text-decoration: none!important;
}

.select-padrao  ::-webkit-scrollbar {
  display: none;
}

.select-padrao ul li span.itemSelected {
  background-color: #f5f5f5;
  transition: all .3s;
}

.select-padrao ul li span.itemSelected:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 61px;
  background-color: #fcd64b;
}
