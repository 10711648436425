// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow: none;
}

.btn, .btn:hover {
  background: var(--color-button-primary);
  color: var(--color-button-primary-text);
}

.modal .modal-content {
  border: none;
}

button {
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
}

button {
  border: none;
}


//========GERAL===========

.c-sidebar {
  &.c-sidebar-minimized {
    .c-sidebar-brand {
      width: auto;

      img {
        height: 13px;
      }
    }
  }
}

.show-only-mobile {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

body {
  background-color: #F7F7F7;
}

.center-vertical {
  align-items: center;
  display: flex;
  padding-right: 15px;
  justify-content: space-between;

  @media(max-width: 768px) {
    padding-right: 15px;
  }
  @media(max-width: 320px) {
    padding-right: 7px;
  }
}

.card-body {
  @media(max-width: 320px) {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.55rem;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.padding-0 {
  padding: 0;
}

a.link-btn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
}

a.link-btn > button {
  font-weight: bold;
  cursor: pointer;
  color: var(--color-button-primary-text);
  font-size: 15px;
}

a.link-btn:hover, a.link-btn:focus, a.link-btn:active {
  text-decoration: none;
}

.wrapper-box {
  padding: 0 20px;
}

.wrapper-center {
  display: flex;
  justify-content: center;
}

.wrapper-center > div {
  width: 50%;
}

.card-infos, .card-btn {
  display: flex;
  flex-direction: column;
}

.card-flex .collapse, .card-flex .card-body {
  min-height: 293px;
}

.card-password.card-flex .collapse, .card-password.card-flex .card-body {
  min-height: 140px;
}

@media (max-width: 768px) {
  .show-only-mobile {
    display: inline;
  }
  .wrapper-center > div {
    width: 90%
  }
  .wrapper-box {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .card-btn a, .card-btn a > button {
    width: 100%;
  }
  .card-flex .collapse, .card-flex .card-body {
    min-height: 259px;
  }
  .card-password.card-flex .collapse, .card-password.card-flex .card-body {
    min-height: 110px;
  }
}

.card-flex .card-body {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.line-card {
  display: flex;
  justify-content: flex-start;
}

.c-sidebar {
  width: 300px;

  .c-sidebar-nav {
    background: #303C4C;
    @media (max-width: 993px) {
      width: 100%;
    }

    &.background-filter-mobile {
      background-color: #F7F7F7;
    }
  }
}


.width-auto {
  width: auto;
}

.marginBottom-55px {
  margin-bottom: 55px;
}

.bottom-fixed-right {
  width: 100%;
}

.bottom-fixed {
  position: absolute;
  bottom: 10px;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.row-marginTop-30 {
  margin: 30px 0 0 0;
}

.width-90-porcent {
  width: 90%;
  margin: 0;
}

.fontWeight-bold {
  font-weight: bold;
}

.fontWeight-300 {
  font-weight: 300;
}

.paddingLeftRight-0 {
  padding-left: 0;
  padding-right: 0;

  .filter-btn {
    background: transparent;
    border: 2px solid var(--color-button-primary);
    border-radius: 9px;
    height: 46px;
    width: 129px;
    color: #5a5e5c;
  }

  .filter-btn:hover {
    background: var(--color-button-primary);
    transition: 2s;
  }
}

.paddingLeftRight-1 {
  margin: 0 0 0 295px;
}

.paddingLeftRight-signature {

  .filter-advanced {
    background: transparent;
    font-weight: 400;
    color: var(--text-default-color-light);
    border-radius: 4px;
    width: 147px;
    height: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 14px;
    }
  }
}

.paddingLeftRight-2 {
  margin-top: 27px;

  @media (max-width: 1250px) {
    margin: 27px 0 0 0;
  }

  .filter-btn {
    background: transparent;
    border: 2px solid var(--color-button-primary);
    border-radius: 9px;
    height: 45px;
    margin-top: 3px;
    width: 129px;
    color: #5a5e5c;
  }

  .filter-btn:hover {
    background: var(--color-button-primary);
    transition: 2s;
  }
}

.paddingLeftRight-btn {
  margin: 30px 0 0;
  @media (max-width: 1315px) {
    margin: 0 70px;
  }

  @media (min-width: 1800px) {
    margin: 27px 0 0 450px;
  }

  .filter-btn {
    background: transparent;
    border: 2px solid var(--color-button-primary);
    border-radius: 9px;
    height: 46px;
    width: 129px;
    color: #5a5e5c;
  }

  .filter-btn:hover {
    background: var(--color-button-primary);
    transition: 2s;
  }
}

.filter-show {
  display: contents;
  @media(max-width: 1315px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 -15px;
  }

  .paddingLeftRight-x {
    .sort-select {
      position: inherit;
    }

    .css-b62m3t-container {
      height: 36px;
      width: 300px;
      margin: 0 0 24px;
      box-shadow: 0 2px 2px 0 #00000036;
      border: none;

      svg {
        width: 20px;
        height: 20px;
      }

      .css-g1d714-ValueContainer {
        display: flex;
        flex-wrap: nowrap;
      }

      .css-b62m3t-container:focus {
        height: 36px;
        width: 300px;
        margin: 0 0 24px;
        box-shadow: 0 2px 2px 0 #00000036;
        border: none;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .rmsc .dropdown-container {
      height: 36px;
      width: 300px;
      margin: 0 0 24px;
      box-shadow: 0 2px 2px 0 #00000036;
      border: none;
    }

    .rmsc .clear-selected-button {
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .select-padrao {
      .dropdown {
        height: 36px;
        width: 300px;
        margin: 0 0 24px -15px;
        box-shadow: 0 2px 2px 0 #00000036;
      }
    }
  }
}

.paddingLeftRight-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.textAlign-right {
  text-align: right;
}

.textAlign-center {
  text-align: center;
}

.textAlign-justify {
  text-align: justify;
}

.textAlign-left {
  text-align: left;
}

.displayFlex {
  display: flex;
}

.minHeight-0 {
  min-height: 0;
}

.width-100-porcent {
  width: 100%;
}

.border-button {
  background: #E0E0E0;
  width: 110px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .img {
    margin-left: 7px;
    margin-right: 0;
    height: 17px;
  }
}

.position-absolute {
  position: absolute;
}

.right-30 {
  right: 30px;
}

.label-button-fixed {
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  color: var(--color-button-primary-text);
}

.padding-10px {
  padding: 10px;
}

.padding-15px {
  padding: 15px;
}

.paddingTop-10px {
  padding-top: 10px;
}

.paddingTop-20px {
  padding-top: 20px;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

.textDecoration {
  text-decoration: line-through
}

.color-secundary {
  color: #7D8380
}

.color-details:hover {
  color: #3e403f
}

.line-height-21px {
  line-height: 21px
}

.marginLeftRight-0 {
  justify-content: space-between;
  display: flex;
  margin-left: 0;
  margin-right: 0;

}

.centralizar-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.sidebar-closeMobile {
  display: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;

}

.a-color {
  color: white !important;
}

//========FIM GERAL ==========


// ==== FILTRO DE BUSCA ====
.dropdown-border-none {
  border: none !important;
}

.sort-select {
  padding: 0 0 0px 15px;
  position: absolute;
  top: 57px;
  width: 15%;
}

.sort-padding {
  padding: 20px 0 0 15px;
}

.short-width {
  width: 100%;
}

.short-mobile {
  display: none;
}

.short-desktop {
  display: block;
}

.short-margin {
  width: 100%;
  padding: 0;
}

.short-margin-desktop {
  width: 97%;
  padding: 0;
  margin: 0 25px;
}

.short-select {
  margin: -13px 0 0 0px;
}

.short-borderColor {
  border-color: white;
}

@media(max-width: 768px) {
  .sort-padding {
    padding: 0;
  }
  .short-mobile {
    display: flex;
    margin: 0;
    width: 100%;
  }

  .short-desktop {
    display: none;
  }

  .short-width {
    width: 87%;
  }
}


// ==== FIM FILTRO DE BUSCA ====


//========MARGIN==============

.marginBottom-5 {
  margin-bottom: 5px
}

.marginTop-7 {
  margin-top: 7px;
}

.marginBottom-40 {
  margin-bottom: 40px;
}

.margin-0 {
  margin: 0;
}

.margin-auto {
  margin: auto;
}

.margin-center {
  margin: 0 auto;
}

//======FIM MARGIN============

//========FONT-SIZE ==========

.fontSize-12 {
  font-size: 12px;
}

.fontSize-13 {
  font-size: 13px;
}

.fontSize-14 {
  font-size: 14px;
}

.fontSize-15 {
  font-size: 15px;
}

.fontSize-16 {
  font-size: 16px;
}

.fontSize-17 {
  font-size: 17px;
}

.fontSize-18 {
  font-size: 18px;
}

.fontSize-19 {
  font-size: 19px;
}

.fontSize-20 {
  font-size: 20px;
}


//====FIM-FONT-SIZE============


//========= HEADER ===========

.c-header {
  background: var(--color-primary);
  min-height: 60px;
}

.c-sidebar .c-sidebar-brand {
  background: var(--color-primary);
  width: 300px;
}

.c-sidebar-brand {
  flex: 0 0 60px;
}

.agroupment-menu {
  display: flex;
  justify-content: flex-end;
}


.signature-header {
  display: none;
  @media(max-width: 769px) {
    width: 100%;
    height: 47px;
    background: #1A202C;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 -15px;
    top: 60px;
    color: #FCD64B;

    p {
      margin-bottom: 0;
      color: #FCD64B;
      margin-left: 10px;
    }
  }
}

.c-header-div {
  background: var(--color-secondary);
}

.cursor-pointer {
  cursor: pointer;
}

.c-header-div:hover {
  background: var(--color-third);
}

.header-name-logged {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.text_goback {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  gap: 4px;
}

.avatar-photo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.header-title {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  margin: 20px 15px 8px 14px;
}

.header-store {
  display: none;
  @media(max-width: 769px) {
    height: 26px;
    width: 164px;
    border-radius: 6px;
    background: #FCD64B;
    display: flex;
    cursor: pointer;
    padding: 15px;
    margin: 25px auto 0;
    justify-content: center;
    align-items: center;
  }
}

.header-store p {
  color: var(--text-default-color-light);
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}

.ql-clipboard {
  display: none;
}

.header-phrase {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 8px 0px 8px;
}

.c-header .c-header-toggler-icon {
  background-image: url(../assets/icons/icon-menu.svg);
}

@media(max-width: 768px) {
  .ml-auto {
    margin-left: auto !important;
  }

  .text_goback {
    display: none;
  }
}

//======= FIM HEADER ========


//======= SIDEBAR ========
#root .c-main .container-fluid,
#root .c-main .container-sm,
#root .c-main .container-md,
#root .c-main .container-lg,
#root .c-main .container-xl,
#root .c-main .container-xxl {
  margin: 0;
  width: 100%;

  @media(max-width: 769px) {
    padding: 0 15px 0 15px;
  }
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 300px;
  }
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -300px;
}

.sidebar-closeMobile {
  display: none;
}

.close-width {
  display: none;
  @media(max-width: 768px) {
    width: 20px;
    display: block;
  }
}

.c-sidebar.c-sidebar-minimized .header-phrase,
.c-sidebar.c-sidebar-minimized .header-title,
.c-sidebar.c-sidebar-minimized .hr-sidebar,
.c-sidebar.c-sidebar-minimized .signature {
  display: none;
}

.c-sidebar.c-sidebar-minimized .align-was-approved {
  display: none;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #283241;
}

.c-sidebar .c-sidebar-nav .c-sidebar-nav-item .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav .c-sidebar-nav-item .c-sidebar-nav-link svg {
  color: var(--dark-text-light)
}

.crown {
  display: none;
  @media(max-width: 769px) {
    visibility: hidden;
    margin: 0 -22px;
  }
}

.header-border {
  border: none;
}

.crown-signature {
  display: none;
}

.notification {
  padding: 0 25px;
  cursor: pointer;
  @media(max-width: 286px) {
    padding: 0;
  }

  .label-danger {
    position: absolute;
    z-index: 1;
    background: #FF0303;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    margin: -25px 11px;
  }

  p {
    margin: 1px 2px;
    width: auto;
    color: #fff;
    font-size: 10px;
  }
}

.signature {
  padding: 12px 12px;
  width: 100%;
  min-height: 48px;
  background: #1A202C;
  color: var(--color-button-primary-strong);
  font-weight: 600;
  height: auto;
  @media(max-width: 769px) {
    display: none;
  }

  button {
    background: transparent;
    border: none;
    color: var(--color-button-primary-strong);
    display: flex;
    padding-top: 1px;
    align-items: center;

    svg {
      margin: 0 9px;
    }
  }
}

.hr-sidebar {
  width: 100%;
  border-width: 2px;
  margin: 15px 0 0 0px;
  border-color: rgba(0, 0, 21, 0.2);
}

.hr-sidebar-phrase {
  width: 100%;
  border-width: 2px;
  margin: 0;
  border-color: rgba(0, 0, 21, 0.2);
}

@media(max-width: 993px) {
  .c-sidebar-show {
    width: 100%;
    margin-left: 0;
  }


  .sidebar-closeMobile {
    display: block;
    width: 65px;
    height: 34px;
    bottom: 50%;
    margin-top: -11px;
  }
}

// @media(max-width: 530px) {
//   .c-sidebar-show-filter {
//     width: 100%!important;
//     margin-left: 0!important;
//   }
// }

.align-was-approved {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;

  .btn-was-approved {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 219px;
    height: 92px;
    border-radius: 15px;
    background: #3B5999;
    border: 1px solid #ffffff;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 300;
      color: #ffffff;
      margin-bottom: 4px;
      text-align: center;

      span {
        text-decoration: underline;
      }
    }

    label {
      color: #ffffff;
      font-size: 12px;
    }

    &:hover {
      background: #2c3c5d;
      transition: 1.5s;
    }
  }
}

//====== FIM SIDEBAR ========


// ====== DASHBOARD =========

.c-body {
  background: #F7F7F7;
}

.inf-general {
  font-style: normal;
  font-size: 15px;
  text-align: center;
  color: #7D8380;
  padding-right: 5px;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
}

.inf-general-normal {
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: var(--text-default-color-light);
  font-weight: 500;
  padding-right: 5px;
  margin-bottom: 0;
  min-width: 53px;
}

.inf-general-name {
  font-style: normal;
  font-size: 15px;
  text-align: left;
  color: #7D8380;
  padding-right: 15px;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
}

.inf-general-name-normal {
  font-style: normal;
  font-size: 16px;
  text-align: left;
  color: var(--text-default-color-light);
  padding-right: 15px;
  margin-bottom: 0;
  font-weight: 500;
  min-width: 53px;
  width: 100%;
}

a.link-itemcourse {
  text-decoration: underline !important;
}

a.link-itemcourse-mobile {
  text-decoration: underline !important;
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
}

p.link-itemcourse {
  margin: 0;
  cursor: pointer;
  color: #3754DB;
  text-decoration: underline;
}

p.link-itemcourse-desktop {
  display: block;
  margin: 0;
  cursor: pointer;
  color: #3754DB;
  text-decoration: underline;

  @media(max-width: 768px) {
    display: none;
  }
}

.inf-value {
  line-height: 16px;
  color: #727272;
}

.inf-value-general {
  font-style: normal;
  font-size: 14px;
  color: #7D8380;
  margin-bottom: 3px;
  word-break: break-word;
  display: flex;
  justify-content: center;
}

.inf-value-general-normal {
  font-style: normal;
  font-size: 14px;
  color: var(--text-default-color-light);
  font-weight: 500;
  margin-bottom: 3px;
  word-break: break-word;
  display: flex;
  justify-content: center;
}

.inf-value-general-card {
  font-style: normal;
  font-size: 14px;
  color: var(--text-default-color-light);
  font-weight: 500;
  margin-bottom: 3px;
  word-break: break-word;
  display: flex;
  justify-content: center;
}

.access {
  text-decoration: underline;
  cursor: pointer;
  width: 106px;
  color: #EB5757;

  //@media(max-width: 768px) {
  //  margin: 0 5px;
  //}
}

.label-expired {
  font-style: normal;
  margin-bottom: 3px;
  word-break: break-word;
  display: flex;
  justify-content: center;
  color: #FF0000;
  font-weight: 500;
}

.button-see-all {
  width: auto;
  margin: auto 35px 30px auto;
  cursor: pointer;
}

.img-button-mobile {
  float: left;
  margin-left: 0 !important;
}

@media(max-width: 768px) {
  .inf-general {
    width: auto;
  }
  .inf-value-general, .label-expired, .label-expired {
    display: contents;
  }
  .inf-value-mobile {
    width: 70%;
  }
  .button-see-all {
    margin-bottom: 25px;
  }
}

@media(max-width: 1330px) {
  .card-mobile {
    flex: none;
    max-width: 100%
  }
  .card-mobile-course {
    flex: none;
    max-width: 90%
  }
}

@media(max-width: 768px) {
  .card-mobile {
    flex: none;
    max-width: 100%
  }
  .card-mobile-course {
    flex: none;
    max-width: 100%
  }
}

.card-mobiles {
  display: none;
  @media(max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  @media(max-width: 769px) {
    display: block
  }
}

.card-desktop {
  display: block;
  @media(max-width: 768px) {
    display: none;
  }
}

//====== FIM DASHBOARD ======


//====== INPUT SEARCH =======


.search-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  padding-left: 20px;

}

.search-general {
  position: relative;
  //width: 70%;
  margin: 20px 0px 20px 34px;
}

.search-general input {
  width: 100%;
}


@media(max-width: 567px) {
  .search-general {
    margin: 20px 0px 20px 15px;
  }
}


//===== FIM INPUT SEARCH ==========


//===== BUTTON VOLTAR INICIO ====

.backhome {
  margin-left: 12px;
}

.backtohome {
  margin-bottom: 18px;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 12px;
  white-space: nowrap;
  border: none;
  background: none;

  a {
    color: var(--color-primary);
  }

  &:hover {
    color: var(--color-primary);
  }
}

.backtohome svg {
  margin-top: -2px;
}

.background-filter-mobile {
  background-color: #F7F7F7;
}

.backtohome-filter {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: black;
  margin-left: 20px;
}

.backtohome-filter svg {
  margin: -2px 13px 0px -10px;
  color: var(--color-primary);
}

@media(max-width: 567px) {
  .backtohome {
    margin-left: 0;
  }
}


//==== FIM BOTAO VOLTAR INICIO ===


.button-fixed {
  width: 200px;
  max-width: 200px;
  min-width: 100px;
  background: var(--color-button-primary);
  min-height: 50px;
  font-size: 15px;
}

.button-fixed-listing {
  width: 100%;
  max-width: 265px;
  min-width: 100px;
  background: var(--color-button-primary);
  min-height: 50px;
  font-size: 15px;
  margin: 0 0 0 auto;
}

a {
  &.button-fixed-listing {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: var(--color-button-primary-text);
    font-weight: bold;
    text-align: center;
  }
}

.button-fixed-listing-normal {
  width: 100%;
  min-width: 100px;
  background: #FDE796;
  min-height: 50px;
  font-size: 15px;
  border-radius: 6px;
  margin: 0 0 0 auto;
}

.button-renew-access {
  width: 100%;
  max-width: 265px;
  min-width: 100px;
  background: var(--color-button-primary);
  min-height: 50px;
  font-size: 15px;
  margin: 0 0 20px auto;
  color: var(--text-default-color-light);
}

.button-fixed-listing > label > label, .button-renew-access > label > label {
  cursor: pointer;
}

.button-pre-sale {
  width: 100%;
  max-width: 265px;
  min-width: 100px;
  background: var(--color-button-primary);
  color: var(--color-button-primary-text);
  min-height: 50px;
  font-size: 15px;
  margin: 0 0 0 auto;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;

  span {
    display: block;
    margin: 0;
    line-height: 1.2;

    b {
      color: #FF0000;
    }
  }

  @media(max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
}


.button-fixed-div {
  width: 100%;
  height: 80px
}

@media(max-width: 768px) {
  .button-fixed, .button-fixed-listing, .button-renew-access {
    width: 100%;
    max-width: none;
  }
}

// ===== Notifications ======

.card-boxnotifications {
  background: #FFFFFF;
  //box-shadow: 0 10px 25px -2px rgba(170, 170, 170, 0.4);
  border-radius: 5px;
  border: 0;
}

.card-header {
  padding: 20px;
}

@media(max-width: 430px) {
  .card-header {
    padding: 20px 0;
  }
}


.card-header-action {
  position: absolute;
  right: -24px;
}

.card-header-general {
  border-color: #fff;
  border-bottom: 0;
  padding-bottom: 0;
}

.card-body-notifications {
  padding: 2.25rem;
}

.title-notifications {
  width: 100%;
  font-style: normal;
  line-height: 24px;
  color: #5a5e5c;
  font-size: 18px;
  margin: 0;
}

.title-notifications-card {
  width: 100%;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #5a5e5c;
  font-size: 18px;
  margin-bottom: 20px;
}

.subtitle-notifications {
  padding: 0 10px 0px 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #7D8380;
}

.close-notification-button {
  display: block;
  height: 34px;
}

.button-notifications-desktop {
  width: auto;
  margin: 10px 20px 9px auto;
  background: var(--color-button-primary);
}

.toaster .toast {
  flex-basis: auto;
  overflow: visible;
  margin-bottom: 1.125rem;
  backdrop-filter: none
}

.toast-header {
  display: block;
}

.close {
  position: absolute;
  bottom: auto;
  right: 21px;

}

.modal-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-button-primary-text);
  margin: 0 auto;
  text-align: center;

}

@media(max-width: 380px) {
  .modal-title {
    margin-top: 20px
  }
}

.modal-header {
  color: black;
  border-bottom: 0;
  margin-top: 5px;

  .close {
    border-color: black;
    padding: 0 3px;
  }
}

.button-notifications-mobile {
  display: none;
}

@media(max-width: 430px) {
  .card-header-general {
    justify-content: center;
    justify-items: center;
    display: flex;
  }

  .padding-header-modal {
    padding: 20px 0px 20px 0px;
  }

  .title-notifications {
    width: 310px;
    padding: 0 25px 0px 10px;
  }

  .card-boxnotifications {
    margin-right: 0;
    margin-left: 0;

  }

  .button-notifications-desktop {
    display: none;
  }

  .button-notifications-mobile {
    margin: auto auto 10px auto;
    display: block;
  }
}

//====== FIM NOTIFICATIONS ======

// =====  PEDIDOS ========

.min-height-Orders {
  min-height: 285px;
}

.min-height-Orders {
  min-height: 0;
}

.box-cinza {
  width: 100%;
  height: auto;
  margin: 0 0 0 0;
  // box-shadow: 0 10px 25px -2px rgba(170, 170, 170, 0.4);
  border-radius: 2px;
  background: #F6F6F9;
}

.c-main .container-fluid {
  padding-right: 0;
  padding-left: 0;
  height: auto;
  width: 97%;
  margin: 0 45px;

}

.title-latest_orders {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-button-primary-text);
  margin-bottom: 15px;
}

.card-boxlatestOrders {
  height: auto;
}

.card-boxlatestOrders .card-header-general {
  padding-bottom: 0;
}

.card-order-detail .card-header-general {
  padding-bottom: 20px;
}

@media(max-width: 768px) {
  .card-padding-order {
    padding-left: 0;
    padding-right: 0;
  }
  .card-btn {
    padding: 10px;
  }
}

.padding-modal-mobile {
  padding-top: 0;
}

@media (max-width: 769px) {
  .padding-modal-mobile {
    padding: 0;
  }
}

.button-latestOrder-mobile {
  width: 90%;
  margin: 0 20px 9px auto;
  background: #9B9B9B;
  padding: 11px;
}

.text-button-latesteOrder {
  font-weight: bold;
  margin: 0;
  color: white;
  float: left;
  padding-left: 10px;
  font-size: 18px;
}

.label-see-all {
  font-weight: bold;
  margin: 0;
  color: #363837;
  cursor: pointer;
}

.orders-details-divMargin {
  margin: 30px 0 0 0;
}

.inf-order-details {
  font-style: normal;
  font-size: 14px;
  color: #7D8380;
  padding-right: 5px;
  margin-bottom: 0;
  min-width: 53px;
}

.inf-value-order-details {
  font-style: normal;
  font-size: 14px;
  color: #7D8380;
  margin-bottom: 3px;
  word-break: break-word;
}

span.help {
  color: #7D8380;
  padding-right: 10px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

@media(max-width: 365px) {
  .orders-details-divMargin {
    margin: 0;
  }
  .text-button-latesteOrder {
    font-size: 14px;
  }
}

@media(max-width: 330px) {
  .text-button-latesteOrder {
    font-size: 13px;
  }
}

.mobile {
  display: none;
}

@media(max-width: 430px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .box-cinza {
    margin: 0;
    padding-top: 20px;
  }

}

@media(max-width: 573px) {
  .card-boxlatestOrders {
    margin: 20px 0 0 0px;
    width: 100%;
  }
}

//======FIM PEDIDOS ========


//======MENSAGEM DE ERRO CAMPO VAZIO =====

.validation-advice {
  position: inherit;
  color: red;
  font-weight: bold;
  width: 100%;
  font-size: 13px;
  margin-top: 4px;
}

//=====FIM MENSAGEM CAMPO VAZIO =====


//=====LOADIDING SPINNER ======

.loading-spinner, .loading-spinner.text-success {
  margin: 50px auto;
  display: flex;
  color: #24ce7d !important
}

.loading-spinner-filter {
  left: 10%;
  top: 50%;
  position: relative;
  margin-left: 0;
  margin-top: -25px;
  width: 25px;
  height: 25px;
}

//====FIM LOADING SPINNER =====


//=======EDITAR CONTATO CLIENTE =====

.form-control:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.custom-select:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

//=======FIM EDITAR CONTATO CLIENTE ===


//======ALERT FIXED ============

.alert-fixed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  z-index: 999999;
  width: 100%;
  left: 0%;
}


//======FIM ALERT FIXED =========


//======MATERIAL IMPRESSO && DIGITAL =========

.namecourse-flex {
  flex: 1 1 35%
}

.inf-digital-printed {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.inf-digital-printed label {
  flex: 1;
  font-style: normal;
  font-size: 15px;
  color: #7D8380;
  margin-bottom: 0;
  text-align: center;
  padding-right: 10px;
}

.inf-digital-printed .color-payment-proccess {
  color: red;
  font-weight: 300;
  flex: 2
}

.div-data-payment {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.div-data-payment-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  color: var(--color-button-primary-text);
  font-weight: 300;
}

.btn-payment-complete {
  margin-right: -5px;
  min-height: 50px;
  min-width: 100px;
  max-width: 200px;
  width: auto;
  flex: 2;
  background: var(--color-button-primary);
  border-radius: 5px;
  color: var(--color-button-primary-text);
  font-weight: 600;
}

.btn-payment-proccess {
  flex: 2;
  padding: 0 10px;
  min-height: 50px;
  min-width: 100px;
  max-width: 200px;
  width: auto;
  margin-right: -5px;
  background: #9B9B9B;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
}

.btn-payment-proccess:hover {
  color: #FFFFFF
}

@media(max-width: 1483px) {
  .div-name-inf {
    flex: 1 1 30%;
  }
}

@media(max-width: 768px) {
  .inf-digital-printed {
    flex: 1 1;
    align-items: start;
    display: flex;
    flex-direction: column;
  }
  .inf-digital-printed label {
    padding-bottom: 10px;
  }
  .div-data-payment {
    display: block
  }
  .btn-payment-complete, .btn-payment-proccess {
    width: 100%;
    max-width: none;
  }

  .padding-itens-impresso {
    padding: 10px 0 0 0px;
  }
  .btn-download-impresso {
    margin-top: 20px
  }
}

.label-formato-material {
  font-style: normal;
  font-size: 15px;
  text-align: center;
  color: #5a5e5c;
  padding-bottom: 10px;
  font-weight: 300;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
}

.btn-row-padding {
  padding: 10px 0px 20px 0px;
}

.btn-details-order-impresso {
  max-width: 200px;
  background: #9B9B9B;
  width: 100%;
  min-height: 32px;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
}

.label-inf-erratas {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5a5e5c;
  padding-right: 5px;
  margin-bottom: 0;
}

.btn-view-errata {
  background: #9B9B9B;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  height: 25px
}

.btn-view-errata:hover {
  color: #fff;
}

.btn-view-errata label {
  height: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  cursor: pointer
}

.center-vertical-impresso {
  justify-content: center;
  align-items: center;
  display: flex;
}

.padding-cardbody-impresso {
  padding: 20px 5px;
}

.button-download-detail {
  position: absolute;
  right: 0;
}

.min-height-digital {
  min-height: 332px;
  max-width: 460px;
}

@media(max-width: 710px) {
  .min-height-digital {
    min-height: 0;
  }
  .label-impresso-digital {
    padding: 0 0 15px 0px;
  }
}

.pendent-payment-digital {
  width: 100%;
  height: 40px;
}

.title-details-digital {
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin: 0;
}

.title-digital {
  color: #5a5e5c;
  font-size: 16px;
  word-wrap: break-word;
  padding: 10px 20px 0px 20px;
  // font-weight: bold;
  margin-bottom: 0;
  width: 100%;
}

.label-digital {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #5a5e5c;
  padding-right: 15px;
}

.label-payment {
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: red;
  margin: 0 auto;
  word-wrap: break-word;
}

.label-type-product {
  font-size: 14px;
  line-height: 21px;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: center
}

.button-details-product {
  max-width: 200px;
  background: #9B9B9B;
  width: auto;
  min-height: 50px;
  margin: 0 0 0 auto;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
}

.button-details-product-choose {
  width: 100%;
  min-width: 100px;
  background: #FDE796;
  min-height: 50px;
  font-size: 15px;
  border-radius: 6px;
  margin: 0 0 0 auto;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;

  .labelink {
    color: var(--text-default-color-light);
  }
}

.button-details-product-go {
  max-width: 89%;
  background: #FDE796;
  width: auto;
  min-height: 50px;
  margin: 0 0 0 auto;
  border-radius: 8px;
  color: var(--text-default-color-light);
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;

  @media(max-width: 768px) {
    max-width: 100%;
  }

  .labelink {
    color: var(--text-default-color-light);
  }
}

.row-details-product {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.row-details-product-padding {
  padding: 0 10px 0px 10px;
}

@media(max-width: 340px) {
  .row-details-product-padding {
    padding: 0;
  }
}

.card-branco-digital-printed {
  width: 80%;
  display: flex;
  //min-height: 340px;
  height: auto;
}

.card-body-digital-printed {
  width: 300px;
  float: left;
  height: auto;
}

.box-cinza-digital-printed {
  width: 95%;
  margin: 0 0 10px 10px;
  //min-height: 210px;
}

.img-digital-printed {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto
}

.card-body-diital-printed {
  margin: 0;
  width: 100%;
  height: auto;
}


@media(max-width: 1400px) {
  .card-branco-digital-printed {
    width: 90%;
  }
  .box-cinza-digital-printed {
    height: auto;
  }
}

@media(max-width: 1215px) {
  .card-body-digital-printed {
    width: 100%;
    float: none;
  }
  .box-cinza-digital-printed {
    margin: 30px 0 0 10px;
  }
  .card-body-digital-printed {
    min-height: auto;
  }
}


@media(max-width: 573px) {
  .card-branco-digital-printed {
    width: 100%;
  }

  .box-cinza-digital-printed {
    padding: 0;
  }
}

//======FIM MATERIAL IMPRESSO && DIGITAL===

button.close {
  font-size: 0.9125rem;
  width: auto;
  padding: 1px 4px 1px 4px;
  background-color: #30989e00;
  border-radius: 20px;
  border-style: solid;
  border-color: #AAAAAA;
  border-width: 2px;
  color: #000000;
}

//============ DETALHES DO PEDIDO =============
.link-details-orders {
  display: flex;
  align-items: center;
}

.default-link {
  font-weight: normal;
  font-size: 14px;
  color: #0072CE;
  display: inline-block;
  background: transparent;
  border: none;
  text-decoration: underline;
}

@media(max-width: 630px) {
  .link-details-orders {
    display: flex;
    align-items: center;
    flex-direction: column
  }
  .link-details-orders a > label {
    display: flex;
    position: relative;
    justify-content: center;
    padding-bottom: 15px;
  }
}

.a-errata-color {
  color: #0072CE
}

.a-errata-color:hover {
  color: #0072CE
}

.padding-details-errata {
  padding: 0 5px 0 0;
  color: var(--color-button-primary-text);
}

.box-cinza-50-porcent {
  padding: 1px 0 0 0px;
  height: auto;
  margin: 0 auto 20px auto;
  width: 95%;
  box-shadow: 0 10px 25px -2px rgba(170, 170, 170, 0.4);
  border-radius: 2px;
  background: #F6F6F9;
}

.hr-ordersdetails {
  box-sizing: border-box;
  width: 97%;
  border: 1px solid #FFFFFF;
}

.card-branco-ordersdetails {
  height: auto;
  margin: 0 auto 40px auto;
  width: 100%;
}

.card-body-ordersdetails {
  margin: 20px 0px 29px 0px;
  width: 100%;
  height: auto;
}

.card-order-detail.card-body-ordersdetails {
  margin: 10px 0px 10px 0px;
}

.padding-modal-orderdetails {
  padding: 5px 10px 10px 10px;
}

.title-orderdetails {
  font-size: 16px;
  font-weight: 300;
  color: #29272E;
}

.card-header-actions-ordersdetails {
  position: absolute;
  right: 30px;
  top: 20px;
}

.card-digitalprinted {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ordersdetails-paddingValueGeral {
  padding: 0 20px 0 0;
}

.ordersdetails-background {
  background-color: #F6F6F9;
}


@media(max-width: 1140px) {
  .card-branco-ordersdetails {
    margin: auto;
    width: 95%;
  }
}

@media(max-width: 768px) {
  .divpai-download-errata {
    margin-top: 20px
  }
}

@media(max-width: 430px) {
  .card-branco-ordersdetails {
    margin: 20px 20px 20px auto;
    width: 100%;
  }
}


//============FIM DETALHES DO PEDIDO===========


//============CARD CURSO ONLINE ===============

.container-course-online > a {
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline !important;
  color: var(--color-button-primary-text) !important;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  cursor: pointer
}

@media(max-width: 555px) {
  .container-course-online > a {
    padding: 20px 25px;
    text-align: center
  }
}

.labelink {
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin: auto;
}

.label-status-course {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 40px 0 10px 0;
}

.label-status-course-x {
  display: none;
}

.label-status-course-go {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 40px 0 10px 0;
}

.label-status-course-dash {
  display: none;
}

.buttonfixedLabel {
  font-weight: bold;
  margin: auto;
  cursor: pointer;
}

.min-max-card-course {
  min-height: auto;
  width: 100%;
}

.card-display-flex {
  display: flex;
}

.space {
  display: inline-flex;
}

@media(max-width: 769px) {
  .label-payment {
    margin: 10px 0;
    display: flex;
  }
  .button-details-product {
    max-width: none;
    width: 100%;
    padding: 0;
    margin-top: 25px;
  }
  .label-status-course {
    padding: 5px;
    margin: 0 auto;
  }
  .card-display-flex {
    display: block;
  }
  .center-vertical {
    display: block;
  }
  .min-max-card-course {
    min-height: auto;
    width: 100%;
  }
  .card-boxlatestOrders {
    width: 500px;
    margin: 15px auto 15px auto;
    @media(max-width: 575px) {
      width: 100%;
    }
  }
}

.name-combo-tail {
  padding: 0 20px 40px 20px;
}

//============DETALHES CURSO ONLINE ===========
.container-modalcourse-expired {
  width: 100%;
  margin: 0;
}

.container-modalcourse-expired p {
  font-family: Roboto;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #7D8380;
}

.container-modalcourse-expired button {
  width: 100%;
  height: 60px;
  max-width: 300px;
  min-width: 200px;
  border-radius: 4px;
  background-color: var(--color-button-primary);
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--color-button-primary-text);
  border: none;
  margin: 0 auto;
}

@media(max-width: 380px) {
  .container-modalcourse-expired button {
    height: 80px;
  }
}

.label-name-material {
  width: 100%;
  font-style: normal;
  line-height: 24px;
  color: var(--color-button-primary-text);
  font-size: 18px;
  margin: 0;
}

.course-details-row {
  flex-direction: row;
  display: flex;
}

.button-details-course {
  width: 100%;
  max-width: 260px;
  min-width: 100px;
  background: var(--color-button-primary);
  min-height: 50px;
  font-size: 15px;
  border-radius: 5px;
}

.margin-div-details-course {
  margin: 30px 0 0 0px;
}

.collecion-img img {
  width: auto;
  height: auto;
  margin-right: 8px;
}

.btn-download-mobile {
  display: none
}

.width-col-namecourse {
  width: 50%;
}

.width-col-course {
  width: 25%;
}

.width-col-name-course {
  width: 40%;
}

.width-col-itemcourse {
  width: auto;
  display: flex;
  flex-direction: column;

}

.width-col-itembuttom {
  width: 17%;

  a {

  }
}

.width-col-itembuttom-expired {
  width: 20%;
}

.width-col-oneitem {
  width: 33%;
}

.width-col-one {
  width: 13%;
  @media(max-width: 1500px) {
    width: 16%;
  }
  @media(max-width: 768px) {
    width: 162px;
    padding-bottom: 16px;
  }
}

.btn-download-resource {
  max-width: 130px;
  background: #9B9B9B;
  width: auto;
  min-height: 40px;
  margin: 0 0 0 auto;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}

.btn-performance-course {
  background: #C4C4C4;
  width: 100%;
  min-height: 40px;
  margin: 0 0 0 auto;
  color: #fff;
  font-weight: 600;
  border-radius: 0;
}

.btn-performance-course:hover {
  background-color: #9B9B9B;
  color: white;
}

.div-padding-performance {
  padding: 0 40px;
}

.div-between-btn {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.div-between-center {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container-option-course {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.container-option-course > a {
  margin-right: 30px;
}

.container-option-course > a.disabled {
  cursor: not-allowed;
}

.container-option-course img {
  height: 20px;
}

.div-between-center > a {
  margin-right: 30px;
  cursor: pointer;
}

.div-between-center > a img {
  cursor: pointer;
  height: 20px;
}

.div-between-center > a.disabled {
  cursor: not-allowed;
}

.progress {
  display: flex;
  height: 25px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  border-radius: 1.25rem;
  background-color: #59C591;
  width: 90%;
  margin: 0 auto;
}

.bg-incorrect {
  background-color: #FE9393 !important;
}

.label-porcentage {
  font-style: normal;
  color: #7D8380;
  margin-bottom: 3px;
  word-break: break-word;
  font-size: 12px;
  text-align: center;
  width: 45px;
}

.label-correct-incorrect {
  font-style: normal;
  color: #7D8380;
  margin-bottom: 3px;
  word-break: break-word;
  font-size: 12px;
  text-align: center;
}

.div-padding-porcentage {
  padding-bottom: 20px;
}

.margin-correct-incorrect {
  margin-bottom: -15px;
}

.span-renew {
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 300;
  color: red;
  text-align: center;
}


// @media(max-width:1366px) {
//   .width-col-itembuttom button {
//     max-width:136px;
//   }
// }

@media(max-width: 768px) {
  .div-between-center {
    justify-content: center;
    align-items: center;
    display: block;
  }
  .padding-itens-recurse {
    padding: 10px 0px 20px 0px;
  }
  .width-col-namecourse, .width-col-oneitem, .width-col-itemcourse,
  .width-col-itembuttom, .width-col-itembuttom-expired {
    width: 100%;
  }
  .width-btn-resourse {
    width: 50%;
  }
  .btn-download-mobile {
    display: flex
  }
  .padding-inf-course {
    display: flex;
    padding: 0 0 10px 0px;
    flex-direction: row;
  }
}


//============FIM DETALHES CURSO ONLINE =================

//============FIM CURSO ONLINE =================

//============RENOVAÇÃO PRODUTO ================

.container-renew {
  margin: 30px auto;
  width: 100%

}

.container-item-renew {
  display: flex;
  justify-content: center;
}

.card-white-renew {
  width: 360px;
  height: 100%;
  margin-right: 25px;
}

.card-white-renew-payment {
  width: 360px;
  height: 100%;
  margin-left: 25px;
}

.card-body-header {
  display: flex
}

.card-body-header img {
  margin: 0 15px 0 0;
}

.card-body-renew {
  width: 90%;
  margin: 0 auto;
  padding: 0;
}

.card-body-renew div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.alert-error {
  border: 1px solid #b94a48;
  background: #f2dede;
  color: #b94a48;
  border-radius: 5px;
  padding: 10px;
  display: table;
  position: fixed;
  z-index: 999;
  left: calc(50% - 50px);
  top: 10%;
  text-align: center;
  width: 345px;
}

@media(max-width: 991px) {
  .alert-error {
    left: calc(40% - 50px);
  }
}

@media(max-width: 700px) {
  .alert-error {
    left: 25%;
  }
}

@media(max-width: 550px) {
  .alert-error {
    left: 20%;
  }
}

@media(max-width: 549px) {
  .alert-error {
    left: 20%;
    width: 271px;
  }
}

@media(max-width: 370px) {
  .alert-error {
    left: 10%;
  }
}

.label-renew {
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.hr-ordersRenew {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #DDDDDD;
}

.btn-renew, .btn-renew label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-right: 5px;
  cursor: pointer;
}

.name-course-renew {
  font-style: normal;
  font-size: 18px;
  line-height: 19px;
  color: #727272;
}

.price-course-renew {
  font-style: normal;
  line-height: 19px;
  text-align: right;
  color: #4A4A4A;
  font-weight: 700;
}

.parcel-course-renew {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: right;

  color: #727272;

}

.box-cinza-course-renew {
  width: 95%;
  margin: 5px auto;
  min-height: 0;
}

.box-cinza-course-renew > div {
  margin: 20px
}

.input-course-renew-name {
  height: 55px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.input-course-renew {
  height: 55px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;

  @media (max-width: 768px) {
    border-color: #E7E7E7;
    border-radius: 7px;
    box-shadow: 0 2px 3px 0 #00000030;
    height: 36px;
  }
}

.input-course-renew-date {
  padding-left: 10px;
  height: 55px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.input-course-renew {
  padding-left: 10px;
  height: 55px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;

  @media (max-width: 768px) {
    border-color: #E7E7E7;
    border-radius: 7px;
    box-shadow: 0 2px 3px 0 #00000030;
    height: 36px;
  }
}

.button-fixed-course-renew {
  background: #FCD64B;
  color: var(--text-default-color-light);
  font-size: 0.75rem;
  font-weight: 600;
  height: 36px;
  width: 318px;
}

.textBox-course-renew {
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  color: #7D8380;
  padding: 10px;
}

@media(max-width: 1400px) {
  .box-cinza-course-renew {
    height: auto;
  }
}

@media(max-width: 1200px) {
  .container-item-renew {
    flex-direction: column;
    justify-content: center;
  }
  .container-renew, .card-white-renew,
  .card-white-renew-payment {
    max-width: 90%;
    width: 100%;
    margin: 0 auto 40px auto;
  }
}

@media(max-width: 573px) {
  .box-cinza-course-renew {
    padding: 0;
  }
}

@media(max-width: 769px) {
  .price-course-renew {
    text-align: justify;
  }

  .parcel-course-renew {
    text-align: justify;
  }

  .button-fixed-course-renew {
    width: 100%;
  }
}

// SUCESSO
.success-container {
  margin: 50px auto 0 auto;
  max-width: 60%;
}

.success-details-order {
  background: #FFFFFF;
  box-shadow: 0 10px 25px -2px rgba(170, 170, 170, 0.4);
  border-radius: 2px;
  border: 0;
}

.success-container-item {
  max-width: 60%;
  margin: 0 auto;
  padding-top: 40px;
}

.description-success {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.description-success label {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4A4A4A;
}

.btn-payment-ticket {
  margin: 20px auto;
  background: var(--color-button-primary);
  border-radius: 5px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-button-primary-text);
}

.btn-payment-ticket > img {
  margin-right: 5px;
}

.check-success {
  border: 1px solid var(--color-primary);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto
}

.success-order {
  text-align: center;
  margin: 30px 0 20px 0;
}

.success-order h4 {
  font-weight: 700;
}

.success-order h5 {
  margin-top: 20px
}

.success-order label {
  font-size: 1em;
}

.success-order > a {
  text-decoration: none;
  color: #4A4A4A
}

@media(max-width: 991px) {
  .success-container {
    max-width: 90%;
  }
}


// FIM SUCESSO

//============FIM RENOVAÇÃO PRODUTO ============


//============MEUS DADOS=============

.mydate-minHeight {
  min-height: 337px;
}

.mydate-pendent-payment {
  width: 100%;
  height: 60px;
}

@media(max-width: 575px) {
  .mydate-minHeight {
    min-height: 0;
  }
}


//=========FIM MEUS DADOS============


// ==== FILTRO E ORDENAÇÃO ====

.form-control-filter:focus {
  border-color: white;
}

.width-filter-name {
  width: 70%;
}

.padding-filter-items {
  padding: 0 0 15px 0px;
}

.fitler-div-button {
  width: 100%;
  margin-top: 5px;
}

.filter-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 50px;
}

.borderColor {
  height: 50px;
  border: solid 1px #ddd;
}

.bordColor {
  border: none;
  width: 630px;
  box-shadow: 0 1px 3px 0 #00000036;
  min-height: 36px;

  @media(max-width: 1315px) {
    width: 415px;
  }
}

.bordColor:focus {
  width: 455px;
  box-shadow: 0 1px 3px 0 #00000036;
  min-height: 36px;

  @media(max-width: 1315px) {
    width: 415px;
  }
}

.filter-button {
  background: #F5F5F5;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  border-color: #CFCCCC;
  width: 100%;
  height: 36px;
  //min-height: 50px;
}

.filter-box .card-body {
  padding-top: 40px;
  padding-bottom: 40px;
}

.filter-box .form-group {
  margin: 0;
}

.form-filter {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: -webkit-fill-available;
}

.filter-box button {
  float: right;
  width: 100%;
  height: 46px;
  max-width: 200px;
  border-radius: 4px;
  background-color: var(--color-button-primary);
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--color-button-primary-text);
  transition: all .3s;
  border: none;
}

.filter-button-order {
  background: #F5F5F5;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  height: 50px;
  font-weight: 600;
}

.filter-button-order.btn {
  color: #5a5e5c;
  border: 2px solid #FDE796;
  display: none; // FIltro avançado
  width: 60%;
  margin: 0 auto;
  border-style: dashed;
  align-items: center;
  justify-content: space-evenly;
}

@media(max-width: 769px) {
  .filter-button-order.btn {
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
  }
}

.filter-header {
  background: #198B54;
}

.filter-goback {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.backtohome-filter svg {
  margin: -2px 13px 0px -10px;
  color: #ffffff;
}

.filter-goback-two {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.backtohome-filter-two {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: black;
  margin-left: 20px;
}

.backtohome-filter-two svg {
  margin: -2px 13px 0px -10px;
  color: #ffffff;
}

.short-button {
  background: var(--color-button-primary);
  height: 50px;
}

.short-button > label {
  font-size: 15px;
}

// ==== FIM FILTRO E ORDENAÇÃO ====


// ==== SEM DADOS ====

.no-data-div {
  width: 40%;
  border: 1px solid #CFCCCC;
  background: #F5F5F5;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 15%;
}

.no-data-img {
  width: 80px;
  margin: 20px auto 0 auto;
  display: block;
  filter: opacity(0.5);
}

.no-data-text {
  color: #9B9B9B;
  text-align: center;
  margin: 20px 0 20px 0;
}

.no-data-test {
  text-align: center;
  margin: 20px 0 20px 0;

  h1 {
    font-size: 28px;
    color: var(--text-default-color);
    font-weight: 700;
    margin-top: 150px;
  }

  p {
    font-size: 14px;
    color: var(--text-default-color-light);
  }

  .btn-payment-proccess-none {
    width: 241px;
    height: 46px;
    background: #FCD64B;
    color: var(--text-default-color-light);
    border-radius: 8px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin-top: 26px;
  }

}

@media(max-width: 575px) {
  .no-data-div {
    width: 90%;
  }
}

// ==== FIM SEM DADOS ====

.group-refresh {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.btn-refresh {
  padding: 0 0 0px 10px;
}

.btn-refresh > img {
  width: 25px;
}

.pull-btn-right {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.item-card-text {
  margin-bottom: 10px;
}

.filter-orders {
  margin-bottom: 70px
}

.card-order {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 10px 25px -2px rgba(170, 170, 170, 0.4);
  border-radius: 2px;
  border: 0;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin-bottom: 25px;
}

.card-order .card-infos {
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-between;
  align-items: center;
}

.card-order .card-infos > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex: 1;
}

.card-order .card-infos > div label {
  padding: 0;
  margin: 0;
  text-align: center;
}

.card-order .card-infos, .success-card {
  width: 100%
}

.card-order .card-infos, .regulate-card {
  width: 75%
}

.card-order .card-btn {
  width: 15%;
  justify-content: center;
  align-items: flex-end;
}

.see-all {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.see-all a label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-button-primary-text);
}

@media (max-width: 991px) {
  .card-order .button-fixed {
    max-width: 130px;
  }
}

@media (max-width: 768px) {
  .card-order {
    flex-direction: column;
  }
  .card-order .card-btn {
    width: 100%;
    padding: 0;
  }
  .card-order .card-infos {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .card-order .card-infos > div {
    flex-direction: row;
    align-items: flex-start
  }
  .card-order .card-infos > div label {
    text-align: left;
    min-width: auto;
  }
  .card-order .card-infos > div label:first-child {
    margin-right: 5px;
  }
  .card-order .button-fixed {
    max-width: 100%;
  }
  .title-latest_orders {
    margin: 0 0 34px 0px;
    font-size: 18px;
    text-align: center
  }
  .filter-button-order {
    margin-bottom: 25px;
  }
}

.item-card-text .imprimir-boleto {
  color: #321fdb;
  text-decoration: underline !important;
}

.info-boleto-confirmacao {
  font-size: 12px;
  font-weight: bold;
  color: #7D8380;
  margin: 0;
}

label.forma-envio {
  font-weight: bold;
}

.space-details {
  margin-top: 20px;
}

.progresso-curso {
  margin-left: 15px;
  font-style: normal;
  font-size: 14px;
  color: #7D8380;
  text-align: center;
}

.pix-group {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media(max-width: 769px) {
    width: 100%
  }
}

.pix-group button {
  border: 1px solid #e6e9f2;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 60px;

}

.file-copy {
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z'/%3E%3C/svg%3E");
  display: flex;
}

.input-pix-copy {
  background: #F6F6F9;
  cursor: not-allowed;
  pointer-events: none;
  width: 85%;
  border: 1px solid #e6e9f2;
  font-size: 16px;
  padding: 12px 32px 12px 16px;
  flex: 1;
}


.pix-info a.imprimir-qr {
  margin-top: 10px;
  color: #7D8380;
  text-decoration: underline !important;
  font-weight: bold;
  display: block;
}

.pix-info > div > svg {
  margin-top: 10px;
  width: auto;
  height: auto;
}

.group {
  display: flex;

  @media (max-width: 769px) {

    flex-direction: column;
    padding-bottom: 0;
  }

  .swiper-wrapper {
    padding-bottom: 40px;
    @media (max-width: 768px) {
      width: 275px;
    }
  }

  h3 {
    width: 14%;
    padding-top: 35px;
    font-weight: 600;
    font-size: 20px;

    @media (max-width: 1800px) {
      width: 39%;
    }

    @media (max-width: 1100px) {
      width: 211%;
    }

    @media (max-width: 768px) {
      text-align: center;
      padding: 0 0 16px;
      width: 72%;
      font-size: 20px;
      margin: 0 auto;
    }
  }

  .cursos {
    display: flex;
    border: 2px solid #198B54;
    width: 375px;
    background: white;
    height: 123px;
    border-radius: 8px;
    padding: 12px 33px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 275px;
      padding: 12px 14px;
    }

    img {
      margin: 33px 272px;
      position: fixed;

      @media (max-width: 768px) {
        margin: 40px 195px;
        position: fixed;
      }
    }

    .nome-curso {
      margin: 0 -15px;
      display: block;
      position: relative;

      p.titulo {
        font-weight: 500;
        font-size: 16px;
        color: var(--text-default-color);
        max-width: 38ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 769px) {
          margin: 0 15px 11px;
          max-width: 27ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    div.nome-curso span {
      position: absolute;
      text-align: center;
      visibility: hidden;
      background-color: #000;
      color: #FFF;
      padding: 5px 10px;
    }

    div.nome-curso span:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-bottom: 8px solid #000;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    div:hover.nome-curso span {
      visibility: visible;
      opacity: 0.9;
      top: 30px;
      left: 0;
      z-index: 999;
    }

    .divisoria {
      margin: 0 0 0 -14px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 769px) {
        margin: 0;
      }

      p {
        font-size: 13px;
        color: var(--text-default-color-light);
        font-weight: 600;
        margin-bottom: 0;
      }

      span {
        color: var(--text-default-color-light);
        font-size: 12px;
        margin-top: 3px;

        max-width: 25ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .info {
        display: block;
        position: relative;

        p.conteudo {
          max-width: 25ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      div.info span {
        position: absolute;
        text-align: center;
        visibility: hidden;
        background-color: #000;
        color: #FFF;
        padding: 5px 10px;
      }

      div.info span:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -8px;
        width: 0;
        height: 0;
        border-bottom: 8px solid #000;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
      }

      div:hover.info span {
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        left: 0;
        z-index: 999;
      }
    }
  }
}

.materiais {
  display: none;
  @media(max-width: 768px) {
    display: block;
  }

  h1 {
    font-weight: 700;

    @media (max-width: 768px) {
      width: 100%;
      align-items: center;
      padding: 0;
      text-align: center;
      font-size: 20px;
    }
  }

  .dash-itens {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
    width: 100%;
    margin: 30px 0;

    @media (max-width: 768px) {
      gap: 11%;
      grid-template-columns: 1fr;
    }

    &.mapa-da-prova {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media(max-width: 1024px) {
        grid-template-columns: none;
      }
    }
  }

  @media(max-width: 1366px) {
    width: 98%;
    margin-top: 50px;
  }
  @media(max-width: 1024px) {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: none;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
    margin: 20px auto 0px;
  }

  button {
    &:disabled {
      opacity: 0.5;
      cursor: wait;
    }

    .loading-spinner {
      position: absolute;
      @media(max-width: 768px) {
        right: 5%
      }
    }
  }
}

.dash-itens > a, .dash-itens > button {
  cursor: pointer;
  background: #828282;
  max-width: 305px;
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  border: none;

  p {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
  }

  &:nth-child(2) p {
    width: 220px;
  }

  @media(max-width: 1024px) {
    max-width: 130px;
    width: 100%;
    align-items: center;
    height: 65px;
    > img {
      width: 20px;
      height: 20px;
    }
    p {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }
    &:nth-child(2) p {
      width: auto;
    }
  }

  .align {
    margin: 25px;
    @media(max-width: 900px) {
      display: none;
    }
  }

  .align-mobile {
    display: none;
    @media(max-width: 900px) {
      display: block;
    }
  }
}

a.mapa-loading {
  cursor: wait;
  opacity: 0.5;
}

// FILTRO

.mobile-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 20px 0 10px 0;
}

.marginLeftRight-x {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  margin-bottom: 28px;
  border-radius: 10px;

  p {
    color: #425466;
    font-size: 14px;
    padding: 10px 0 0 15px;
  }

  .fitler-div-button {
    margin: 0;
  }

  .input-course-renew-name {
    box-shadow: 0 1.3px 3px 0 #00000070;
    border: none;
    height: 46px;
  }
}

.marginBottom-x {
  margin-bottom: 15px;
}

.content {
  height: 146px;
  overflow-y: auto;
}

.checkbox {
  margin-bottom: 13px;

  input {
    width: 18px;
    height: 18px;
    border: 3px solid rgba(0, 0, 0, .1);
  }

  label {
    padding: 3px 0 0 9px;
    color: var(--text-default-color);
  }
}

.mobile-button {
  background-color: #FCD64B;
  height: 46px;
  width: 131px;
  margin: 0 auto 15px;
}


//Detalhes
.details-title {
  h1 {
    font-weight: 700;
  }
}

.detail-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;

  .card-first {
    .link-details-order {
      button {
        background-color: #FCD64B;
        width: 240px;
        margin-bottom: 30px;
        height: 46px;
        margin-top: 35px;
      }

      button:hover {
        transition: 2s;
        background: #FDE796;
      }
    }

    .details {
      .paddingLeftRight-0 {
        display: flex;

        .fade {
          padding: 0 12px;
        }

        .card-boxlatestOrders {
          width: 105px;
          height: 70px;
          padding: 10px;
          display: flex;
          align-items: center;

          label {
            color: var(--text-default-color);
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            margin-bottom: 0;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }

      .neutral {
        color: var(--text-default-color-light);
        width: 252px;
        margin: 0 auto;
        font-weight: 600;

        p {
          font-weight: 500;
          padding-top: 10px;
        }

      }
    }

    .button-description {
      p {
        font-size: 12px;
      }

      button {
        background: transparent;
        border: 2px solid #FCD64B;
        border-style: dashed;
        width: 169px;
        font-size: 12px;
        height: 12px;
      }

      button:hover {
        transition: 2s;
        background: #FDE796;
      }
    }
  }

  .card-class {
    background: white;
    width: 700px;
    margin: 35px 0 0 -180px;
    height: 489px;
    border-radius: 5px;

    img {
      cursor: pointer;
      padding: 0 5px 0 0;
      width: 18px;
    }

    p {
      cursor: pointer;
      font-weight: 600;
      padding: 18px 14px;
    }

    .class-secret {

      img {
        width: 16px;
        padding: 0;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: var(--text-default-color);
      }

      .line {
        border-bottom: 1px solid #F2F2F2;
        margin: 40px 0 0 0;
      }

      .class-second {
        display: flex;
        margin: -22px 33px;

        img {
          padding: 0 5px 0 0;
        }

        span {
          border: 2px solid #FCD64A;
          border-radius: 5px;
          height: 30px;
          padding: 5px 13px;
          font-size: 11px;
          font-weight: 600;
          margin: 0 18px 0 0;
          color: var(--text-default-color-light);
        }
      }
    }
  }
}


//MEUS CURSOS//


.mycourse {
  .borderColorLicense {
    border: none;
  }

  .form-control {
    width: 495px;
    box-shadow: 0 1px 3px 0 #00000036;
    height: 46px;
  }

  .form-control:focus {
    box-shadow: 0 1px 3px 0 #00000036;
    border: none
  }

  .paddingLeftRight-0 {
    .filter-button {
      background: transparent;
      border: 2px solid #EDC947;
      border-radius: 9px;
      height: 46px;
      width: 129px;
      max-width: 65%;
      margin-top: 36px;
    }

    .filter-button:hover {
      background: #FDE796;
      transition: 2s;
    }
  }

  .button-fixed-listing {
    width: 100%;
    max-width: 265px;
    min-width: 100px;
    background: #FDE796;
    min-height: 50px;
    font-size: 15px;
    margin: 0 0 0 auto;
  }
}


.short-mobile-card {
  display: none;
}

.paddingLeftRight {
  display: none;
}

.button-fixed-listing-mobile {
  width: 127px;
  min-width: 100px;
  background: var(--color-button-primary);
  border-radius: 8px;
  min-height: 50px;
  font-size: 15px;

  @media(max-width: 768px) {
    float: right;
    margin-bottom: 15px;
  }
}


@media(max-width: 768px) {
  .short-mobile-card {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .filter-button-order-mobile {
    display: flex;
    background: #F5F5F5;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 100%;
    height: 50px;
    font-weight: 600;

    img {
      width: 16px;
    }
  }

  .filter-button-order-mobile.btn {
    color: #5a5e5c;
    border: 2px solid #FDE796;
    display: flex;
    width: auto;
    height: 34px;
    margin: 0 auto;
    border-radius: 6px;
    border-style: dashed;
    align-items: center;
    justify-content: space-evenly;
  }

  .padding-inf-courser {
    padding: 0 0 10px 0px;
    display: block;

    @media(max-width: 768px) {
      display: flex;
      flex-direction: row;
    }

    @media(max-width: 575px) {
      display: -webkit-inline-box;
    }
  }

  .button-fixed-listing-mobile {
    width: 130px;
    max-width: 265px;
    min-width: 100px;
    background: var(--color-button-primary);
    min-height: 50px;
    font-size: 15px;
    border-radius: 6px;
  }

  .paddingLeftRight {
    display: flex;
    padding-top: 13px;

    img {
      width: 16px;
      margin: -3px 6px 0 0;
    }

    .filter-advanced-mobile {
      background: transparent;
      border: 2px dashed var(--color-button-primary);
      border-radius: 4px;
      width: 130px;
      height: 47px;
      margin: 0 0 -68px 0;
      color: #5a5e5c;

      btn {
        margin: 5px auto;
      }

    }
  }
}

.lupa {
  display: flex;
  margin: -41px 0 0 210px;
  cursor: pointer;
  border: 2px solid var(--color-button-primary);
  width: auto;

  @media (max-width: 279px) {
    margin: -41px 0 0 155px;
  }
  @media (min-width: 280px) {
    margin: -41px 0 0 155px;
  }
  @media (min-width: 320px) {
    margin: -41px 0 0 193px
  }
  @media (min-width: 360px) {
    margin: -41px 0 0 230px;
  }
  @media (min-width: 400px) {
    margin: -41px 0 0 265px;
  }
  @media (min-width: 440px) {
    margin: -41px 0 0 300px;
  }
  @media (min-width: 490px) {
    margin: -41px 0 0 350px;
  }
  @media (min-width: 540px) {
    margin: -41px 0 0 395px;
  }
  @media (min-width: 580px) {
    margin: -41px 0 0 430px;
  }
  @media (min-width: 640px) {
    margin: -41px 0 0 485px;
  }
  @media (min-width: 700px) {
    margin: -41px 0 0 540px;
  }
  @media (min-width: 740px) {
    margin: -41px 0 0 580px;
  }
  @media (min-width: 768px) {
    margin: -41px 0 21px 91%
  }

  p {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .form-control {
    border-color: #E7E7E7;
    border-radius: 10px;
    height: 46px;
    width: 102%;

    .input-course-renew-name {
      border-color: #E7E7E7;
      border-radius: 7px;
      box-shadow: 0 2px 3px 0 #00000030;
      height: 36px;
    }
  }
}

.btn-mobile {
  display: flex;
  justify-content: center;

  .short-button-mobile {
    background: var(--color-button-primary);
    height: 50px;
    width: 131px;
    border-radius: 8px;
  }
}

.title {
  padding-top: 30px;

  p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: var(--text-default-color);
  }
}

// Meus cursos Assinatura

.inf-value-general-signature {
  font-size: 14px;
  margin-bottom: 3px;
  word-break: break-word;
  display: flex;
  justify-content: center;
  color: var(--text-default-color-light);

  label {
    color: var(--text-default-color-light);
    font-weight: 500;
  }
}

.inf-general-name-signature {
  font-style: normal;
  font-size: 16px;
  text-align: left;
  color: var(--text-default-color-light);
  font-weight: 500;
  padding-right: 15px;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
}

.button-fixed-listing-signature {
  width: 130px;
  max-width: 265px;
  min-width: 100px;
  background: #FDE796;
  min-height: 50px;
  font-size: 15px;
  border-radius: 6px;
}

.label-status-course-signature {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.cursos {
    .escolher-curso {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .meus-materiais-p {
    @media (max-width: 768px) {
      display: none;
    }
  }

  label.label-dark {
    font-weight: 500;
    color: var(--text-default-color);
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    label {
      display: none;
    }
    label.label-dark {
      display: block;
      margin-top: 8px;
      color: var(--text-default-color);
    }
  }

  .question {
    display: flex;

    label {
      display: block;
    }

    img {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }

  img {
    margin: 0 3px 0 0;
    width: 16px;
  }

  p {
    font-weight: 500;
    color: #29272E;
    @media (max-width: 768px) {
      display: block;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  a {
    display: flex;
    color: #198B54;
    font-weight: 300;
  }

  span {
    color: #198B54;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
    font-weight: 500;
    color: var(--text-default-color-light);
  }

}

.label-status-course-signature-mobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 0 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 768px) {
    justify-content: center;
  }

  img {
    padding: 0 5px;
  }

  a {
    font-weight: 600;
    color: #198B54;
    padding: 0 0 10px 0;
    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.width-col-itembuttom-kit {
  width: 7%;
  @media (max-width: 1700px) {
    width: 14%;
  }
  @media (max-width: 1250px) {
    width: 20%;
  }
}

.width-col-itembuttom-normal {
  width: 6%;
  @media (max-width: 1700px) {
    width: 14%;
  }
  @media (max-width: 1250px) {
    width: 20%;
  }
}

.width-col-itembuttom-signature {
  width: 6%;
  @media (max-width: 1700px) {
    width: 14%;
  }
  @media (max-width: 1250px) {
    width: 20%;
  }
}

.btn-three-go {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0;
  font-size: 1rem;
  border: none;
  background: none;
  opacity: 0;
  @media (max-width: 768px) {
    display: none;
  }
}

//.mobile-btn {

#button-del {
  @media (max-width: 768px) {
    display: none;
  }
}

.btn-three {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  @media (max-width: 1400px) {
    padding: 0.5rem 1rem;
  }
  @media (max-width: 768px) {
    display: block;
  }

  button {
    display: none;
  }
}

.btn-three-downloadable {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 2rem;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  @media (max-width: 1400px) {
    padding: 0.5rem 1rem;
  }
  @media (max-width: 768px) {
    display: block;
  }

  button {
    display: none;
  }
}

//}

.outsider {
  border: 2px solid #E8E9EB;
  border-radius: 12px;
  width: 115px;
  height: 58px;

  .deep {
    padding: 6.6px 0 0 14px;

    p {
      cursor: pointer;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: var(--text-default-color-light);
    }

    p:hover {
      color: black;
      transition: 1s;
    }
  }
}

.button-fixed-listing-signature-two {
  width: 127px;
  background: var(--color-button-primary);
  height: 50px;
  font-size: 15px;
  border-radius: 6px;
  @media(max-width: 768px) {
    width: 500%;
    height: 36px;
  }
}

.inf-general-card {
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-default-color-light);
  text-align: center;
  padding-right: 5px;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
}

.button-fixed-listing-signature-three {
  width: 100%;
  min-width: 100px;
  background: #FDE796;
  min-height: 50px;
  font-size: 15px;
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 586%;
    height: 36px;
    margin: 15px 0 0 0;
  }
}

.label-status-course-signature-mobile-card {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }

  img {
    padding: 0 5px;
  }

  a {
    font-weight: 600;
    color: #198B54;
    padding: 10px 0 10px 0;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  label {
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0;
  }

  span {
    color: #198B54;
  }
}

.inf-general-card-mobile {
  display: none;
  font-style: normal;
  font-size: 14px;
  color: #7D8380;
  padding-right: 5px;
  margin-bottom: 0;
  min-width: 53px;
  width: 100%;
  @media (max-width: 768px) {
    display: block;
  }
}


// IMPRESSO-DIGITAL

.cursor-pointer {
  .borderColor-Digital {
    border: none;
    width: 455px;
    box-shadow: 0 1px 3px 0 #00000036;
    min-height: 36px;
  }

  .borderColor-Digital:focus {
    border: none;
    width: 455px;
    box-shadow: 0 1px 3px 0 #00000036;
    min-height: 36px;
  }

  .filter-button-digital {
    background: transparent;
    border: 2px solid var(--color-button-primary);
    border-radius: 9px;
    height: 46px;
    width: 139px;
    margin-top: 36px;
    color: #5a5e5c;
  }
}

.btn-payment-complete-digital {
  width: 190px;
  max-width: 190px;
  background: var(--color-button-primary);
  font-size: 15px;
  border-radius: 8px;
  font-weight: 600;
  margin: 0 auto;
  height: 50px;

  @media(max-width: 768px) {
    width: 250px;
    max-width: 100%;
    height: 36px;
  }
}

.btn-payment-proccess-digital {
  width: 190px;
  max-width: 190px;
  background: var(--color-button-primary);
  font-size: 15px;
  border-radius: 8px;
  font-weight: 600;
  margin: 0 auto;
  height: 50px;

  @media(max-width: 768px) {
    width: 250px;
    max-width: 100%;
    height: 36px;
  }
}

.label-status-course-digital {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 40px 0 10px 0;
  @media(max-width: 768px) {
    display: none;
  }
}

.div-data-payment-digital {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  opacity: 0;
  @media(max-width: 768px) {
    display: none;
  }

  label {
    color: #262626;
  }
}

.inf-digital-printed-digital {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.inf-digital-printed-digital label {
  flex: 1;
  font-style: normal;
  font-size: 15px;
  color: var(--text-default-color-light);
  margin-bottom: 0;
  text-align: center;
  padding-right: 10px;
}

.inf-digital-printed-digital .color-payment-proccess {
  color: red;
  font-weight: 300;
  flex: 1;
}

@media(max-width: 768px) {
  .inf-digital-printed-digital {
    flex: 1 1;
    align-items: start;
    display: flex;
    flex-direction: column;
  }
  .inf-digital-printed-digital label {
    padding-bottom: 10px;
  }
}

.only-mobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 40px 0 10px 0;
  display: none;
  @media(max-width: 768px) {
    padding-top: 16px;
    font-size: 16px;
    display: flex;
    color: var(--text-default-color);
    font-weight: 600;
    margin: 0 auto;
  }
}

.search {
  p {
    color: black;
  }
}

// ESCOLHER APOSTILAS

.div-data-payment-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  opacity: 1;

  label {
    //color: #262626;
    color: var(--text-default-color-light);
  }

  @media(max-width: 768px) {
    display: block;
  }
}

.only-mobile-digital {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color);
  padding: 40px 0 10px 0;
  display: none;
  @media(max-width: 768px) {
    display: none;
  }
}

.button-fixed-listing-go {
  width: 150px;
  max-width: 100%;
  background: var(--color-button-primary);
  border-radius: 8px;
  height: 50px;
  font-size: 15px;
  margin: 0 0 0 auto;
  @media (max-width: 769px) {
    width: 250px;
    max-width: 100%;
    margin: 24px auto 0;
    height: 36px;
  }
}

.tooltip-border {
  animation: slidein 0.2s;
}

#tooltip {
  background-color: #fff;
  color: var(--text-default-color-light);
  height: 38px;
  z-index: 2;
  padding: 5px;
  width: 125px;
  border-radius: 12px;
  font-size: 13px;
  position: absolute;
  inset: auto 0 0 auto;
  margin: 0;
  transform: translate(-18px, 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 3px 0px #00000078;

  @media (max-width: 1313px) {
    transform: translate(-18px, 7px);
  }
  @media (max-width: 768px) {
    transform: translate(-45px, -165px);

    @media (max-width: 400px) {
      transform: translate(-23px, -170px);
    }

    @media (max-width: 360px) {
      transform: translate(-20px, -165px);
    }
  }

  p {
    margin: 5px;
    z-index: 1;
  }

  p:hover {
    color: black;
    transition: 3s;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#arrow,
#arrow::before {
  position: absolute;
  width: 9px;
  height: 9px;
  background: inherit;
  margin: 15px 0 0 -3px;
  box-shadow: 0 0 32px 3px #00002b;

  @media (max-width: 768px) {
    display: none;
    margin: -13px 0 0 30px;
  }
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

#tooltip-mobile {
  background-color: #fff;
  color: var(--text-default-color-light);
  height: 38px;
  z-index: 2;
  padding: 10px;
  width: 125px;
  border-radius: 12px;
  font-size: 13px;
  position: absolute;
  inset: auto 0 0 auto;
  margin: 0;
  transform: translate(-27px, -180px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 3px 0px #00000078;

  p {
    margin-bottom: 0;
  }

  p:hover {
    color: black;
    transition: 3s;
  }
}

// escolher impressa digital

.cursor-pointer {
  .borderColor-Digital {
    border: none;
    width: 548px;
    box-shadow: 0 1px 3px 0 #00000036;
    min-height: 36px;
  }

  .borderColor-Digital:focus {
    border: none;
    width: 548px;
    box-shadow: 0 1px 3px 0 #00000036;
    min-height: 36px;
  }

  .filter-button-digital-printed {
    background: transparent;
    border: 2px solid #EDC947;
    border-radius: 9px;
    height: 46px;
    width: 139px;
    margin-top: 23px;
  }
}

.btn-payment-complete-digital-printed {
  width: 150px;
  max-width: 265px;
  height: 46px;
  display: flex;
  background: #FDE796;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media(max-width: 768px) {
    width: 250px;
    height: 36px;
  }
}

.div-data-payment-digital {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  opacity: 1;

  label {
    //color: #262626;
    color: var(--text-default-color-light);
  }

  @media(max-width: 768px) {
    display: block;
    margin-bottom: 25px;
  }
}

.label-status-course-digital-impresso {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-default-color-light);
  padding: 40px 0 10px 0;

  span {
    color: #198B54;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 768px) {
    display: none;
  }
}

.padding-inf-course-digital {
  opacity: 0;
  @media (max-width: 769px) {
    display: none;
  }
}

.btn-aux {
  display: flex;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  background: none;
  opacity: 0;
  @media (max-width: 768px) {
    display: none;
  }
}

.card-boxlatestOrders-digital {
  width: 100%;
  @media (width: 768px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 200%;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

.card-boxlatestOrders-signature {
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

.modal-rescue {
  display: flex;
  flex-direction: column;
  margin: -50px -40px 0 -35px;

  p {
    color: var(--text-default-color);
    width: 190%;
    font-size: 16px;
    font-weight: 300;

    @media(max-width: 475px) {
      width: 100%;
    }
  }

  label {
    width: 101%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;
  }

  span {
    width: 190%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color-light);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;
  }
}

.modal-content-remove {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 198px;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 0.3rem;
  outline: 0;
  background-color: #fff;
  border-color: rgba(0, 0, 21, 0.2);
}

.modal-rescue-lg {
  height: 198px;
  width: 345px;
  @media(max-width: 769px) {
    width: 95%;
    margin: 225px auto;
  }
}

.modal-buttons {
  display: flex;
  justify-content: center;
  padding: 12px 0 0;
  width: 100%;

  button {
    margin: 0 20px 0;
    font-weight: 600;
    width: 135px;
    height: 36px;
    border: 2px solid #EDF2F7;
    border-radius: 6px;
  }

  .button-color {
    background-color: #FCD64B;
    border: 2px solid #FCD64B;
    color: var(--text-default-color-light);
    width: 135px;
  }
}

// fim

// MODAL REMOVE

.modal-remove {
  display: flex;
  flex-direction: column;
  margin: -50px -34px 0;
  @media(max-width: 575px) {
    margin: -50px 0 0;
  }

  p {
    color: var(--text-default-color);
    width: 191%;
    font-size: 16px;
    font-weight: 300;

    @media(max-width: 575px) {
      width: 108%;
    }
  }

  label {
    width: 228%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;

    @media(max-width: 769px) {
      width: 212%;
    }

    @media(max-width: 575px) {
      width: 110%;
    }
  }

  span {
    width: 190%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color-light);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;
  }
}

.modal-content-remove {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 198px;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 0.3rem;
  outline: 0;
  background-color: #fff;
  border-color: rgba(0, 0, 21, 0.2);
}

.modal-remove-lg {
  height: 198px;
  width: 345px;
  @media(max-width: 769px) {
    width: 80%;
    margin: 225px auto;
  }
}

.modal-buttons-remove {
  display: flex;
  justify-content: center;
  padding: 12px 0 0;
  width: 100%;

  button {
    margin: 0 20px 0;
    font-weight: 600;
    width: 135px;
    height: 36px;
    border: 2px solid #EDF2F7;
    border-radius: 6px;
  }

  .button-color {
    background-color: #FFE6E4;
    border: 2px solid #FFE6E4;
    color: #F16063;
    width: 135px;
  }
}

// modal warn rescue

.modal-warn {
  display: flex;
  flex-direction: column;
  margin: -50px -35px 0;

  p {
    color: var(--text-default-color);
    width: 215%;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;

    @media(max-width: 475px) {
      width: 100%;
    }
  }

  label {
    width: 100%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;

    @media(max-width: 769px) {
      width: 100%;
    }
  }

  span {
    width: 190%;
    font-style: normal;
    font-size: 12px;
    color: var(--text-default-color-light);
    padding-right: 5px;
    margin-bottom: 0;
    min-width: 53px;
  }
}

.modal-content-remove {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 198px;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 0.3rem;
  outline: 0;
  background-color: #fff;
  border-color: rgba(0, 0, 21, 0.2);
}

.modal-warn-lg {
  height: 216px;
  width: 345px;
  @media(max-width: 769px) {
    width: 95%;
    margin: 225px auto;
  }
}

.modal-buttons-warn {
  display: flex;
  justify-content: center;
  padding: 12px 0 0;
  width: 100%;

  button {
    margin: 0 35px 0;
    font-weight: 600;
    width: 111px;
    height: 36px;
    border: 2px solid #FCD64A;
    border-radius: 6px;
  }
}

.mapa-loading {
  cursor: wait;
  opacity: 0.5;
}

.link-btn {
  border: none;
  width: 100%;

  &:focus, &:active, &:hover {
    border: none;
  }
}

.swiper-container-pointer-events {
  width: 100%;
}

.item-name {
  width: 100%;
  margin-bottom: 10px;
}

.modal-digital {
  width: 600px;

  @media(max-width: 769px) {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  .card-body-digital {
    display: flex;
    @media(max-width: 769px) {
      flex-direction: column;
    }
  }

  .title-digital {
    text-align: revert;
    padding: 0 20px;
    color: var(--text-default-color);
  }

  .modal-body {
    padding: 0 1rem 1rem;
  }

  .slide {
    width: 32%;
    @media(max-width: 769px) {
      width: 100%;
    }

    .paginate {
      margin: 0 0 36px 0;
    }
  }
}

.nota {
  display: flex;

  ul {
    display: flex;
    align-items: center;

    li {
      display: block;
      margin-right: 7px;

      i {
        font-size: 0.75rem;
        color: #FEB043;
      }
    }
  }

  span {
    display: block;
    font-size: 0.875rem;
    line-height: 1.5625rem;
    color: #727272;
    opacity: 0.7;
  }
}

.alinhamento {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.group-digital {
  display: flex;
  flex-direction: column;

  .btn-digital {
    background-color: #FCD64B;
    border: none;
    height: 46px;
    width: 250px;
    border-radius: 8px;
    color: var(--text-default-color-light);
    font-weight: 600;
    margin: 0 auto;
  }

  .text {
    display: flex;
    flex-direction: column;
    margin: 5px 20px 25px;

    p {
      color: var(--text-default-color-light);
      font-size: 14px;
      margin-bottom: 6px;
    }

    a.link {
      text-decoration: underline !important;
      font-size: 14px;
      color: #3754DB;
    }
  }
}

.modal-img {
  height: 207px;
  @media(max-width: 769px) {
    display: flex;
    margin: 18px auto;
  }
}

.material {
  text-align: center;
  margin: 20px;

  a.link {
    text-decoration: underline !important;
    font-size: 14px;
    color: #3754DB;

    img {
      padding: 0 2px 0 0;
    }
  }
}

.divContent {
  overflow: auto;
  height: 320px;
  margin-top: 30px;

  p.descricao {
    font-size: 12px;
  }

  @media(max-width: 769px) {
    overflow: hidden;
    height: auto;
    margin: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #3a3939;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(73, 73, 73, 0.95);
}

.avaliacao {
  margin-top: 22px;

  @media(max-width: 769px) {
    margin: 0;
  }

  p {
    font-size: 16px;
    color: var(--text-default-color);
  }

  p.nome {
    font-size: 12px;
    margin-bottom: 0;
  }

  p.texto {
    font-size: 12px;
    margin-bottom: 0;
  }

  .nota {
    ul {
      margin-bottom: 0;
    }
  }

  @media(max-width: 769px) {
    padding: 0 20px 30px;
  }
}


.modal-description {
  @media (max-width: 769px) {
    margin: 140px auto;
    width: 85%;
  }

  .card-header {
    padding-bottom: 5px;
  }

  .modal-body {
    padding: 10px;
    overflow: auto;
    height: 303px;
  }

  .description {
    .p5 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F2F2F2;
      padding: 0 0 10px 0;
    }
  }
}

.modal-signature {
  @media (max-width: 769px) {
    margin: 60% auto;
    width: 85%;
  }

  .card-header {
    padding-bottom: 5px;
  }

  .modal-body {
    padding: 10px;
  }

  .assinatura {
    text-align: center;

    .p1 {
      color: var(--color-primary);
      font-size: 16px;
    }

    .p2 {
      font-size: 16px;
    }

    .p3 {
      font-size: 16px;
    }

    .p4 {
      font-size: 14px;
    }
  }
}

// DETALHES CURSOS

.title-details {
  h1 {
    font-weight: 700;
    font-size: 28px;
    color: var(--text-default-color);
    margin: 0 31px;

    @media(max-width: 910px) {
      text-align: center;
    }
  }
}

.group-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 95%;
  margin: 35px auto;
  align-items: baseline;

  @media(max-width: 910px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .link-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1799px) {
      width: 90%;
    }

    .button-details {
      width: 100%;
      max-width: 260px;
      min-width: 100px;
      background: var(--color-button-primary);
      min-height: 46px;
      font-size: 14px;
      border-radius: 8px;
    }

    .button-map {
      margin-top: 26px;
      margin-bottom: 7px;
      width: 100%;
      max-width: 208px;
      min-width: 100px;
      background: #E4ECF7;
      min-height: 36px;
      font-size: 12px;
      border-radius: 6px;
    }

    p.button {
      text-align: center;
      color: var(--text-default-color-light);
      font-size: 12px;
      width: 100%;
      max-width: 233px;
    }

    .hr-details-phrase {
      width: 100%;
      margin-top: 25px;
      border-color: #fff;

      @media(max-width: 768px) {
        display: none;
      }
    }

    .information {
      width: 100%;
      max-width: 252px;

      p.question {
        margin-top: 25px;
        color: var(--text-default-color-light);
        font-size: 14px;
        font-weight: 700;
      }

      p.content {
        color: var(--text-default-color-light);
        font-size: 14px;
        height: auto;
      }

      .description {
        display: flex;
        justify-content: center;

        button {
          width: 169px;
          display: flex;
          height: 36px;
          border: 2px dashed #FCD64B;
          font-size: 12px;
          border-radius: 5px;
        }
      }

      .links {
        display: flex;
        gap: 20px;
        padding-top: 20px;
        @media(max-width: 910px) {
          margin-bottom: 20px;
        }

        .square {
          background-color: white;
          width: 100%;
          max-width: 105px;
          height: 70px;
          display: flex;
          margin: 0 auto;
          flex-direction: column;
          padding-top: 11px;
          align-items: center;
          border-radius: 5px;

          label {
            margin: 0;
            font-size: 18px;
            color: var(--text-default-color);
          }

          span {
            font-size: 14px;
            color: var(--text-default-color);
          }
        }
      }
    }
  }

  .details-group {
    @media (max-width: 910px) {
      display: none;
    }

    .card-details {
      width: 1100px;
      height: auto;
      background-color: white;
      border-radius: 5px;


      @media (max-width: 1799px) {
        margin: 0 30px;
        width: 750px;
      }
      @media (max-width: 1320px) {
        width: 600px;
        margin: 0;
      }
      @media(max-width: 1160px) {

      }

      hr {
        border-color: #F2F2F2;
      }

      p {
        font-size: 14px;
        line-height: 22.68px;
        padding: 0 30px;
        font-weight: bold;
        color: var(--text-default-color);
        margin: 0;
      }

      .progresso {
        margin: 20px 0 0 5px;
      }

      .details-link {
        .details-header {
          .card-title {
            display: flex;
            justify-content: space-between;
            margin: 30px 0 0 0;
            padding: 12px 0;

            .arrow {
              margin: 0 30px 0 0;
              cursor: pointer;
              width: 18px;
            }
          }
        }
      }

      .lock .hide-options {
        display: none;
        animation: lock 0.3s;
      }

      @keyframes lock {
        from {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
        to {
          transform: translate3d(0, -60px, 0);
          opacity: 0;
        }
      }

      @keyframes animation {
        from {
          transform: translate3d(0, -60px, 0);
          opacity: 0;
        }
        to {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }

      img {
        margin: 0 5px 0 0;
        width: 14px;
      }

      span {
        font-weight: normal;
        color: var(--text-default-color-light);
      }

      span.link {
        text-decoration: underline;
      }

      .card-options {
        display: flex;
        justify-content: center;

        p {
          cursor: pointer;
          border: 2px solid var(--color-button-primary);
          width: 19.5%;
          margin: 0 25px 0 0;
          font-size: 10px;
          font-weight: 600;
          border-radius: 5px;
          color: var(--text-default-color-light);
          display: flex;
          height: 36px;
          justify-content: center;
          align-items: center;

          @media (max-width: 1799px) {
            width: 16.5%;
          }
        }
      }

      .card-informations {
        p {
          font-weight: normal;
          padding: 10px 15px 14px;
          cursor: pointer;
        }
      }

      .open .hide-options {
        animation: animation 0.3s;
        display: block;
      }

      .lock .arrow {
        animation: fechar .2s;
        transform: rotate(0deg);
      }

      @keyframes fechar {
        from {
          -webkit-transform: rotate(180deg);
        }
        to {
          -webkit-transform: rotate(0deg);
        }
      }

      .open .arrow {
        animation: girar .2s;
        transform: rotate(180deg);
      }

      @keyframes girar {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(180deg);
        }
      }

    }
  }

  // mobile
  .details-group-mobile {
    display: none;
    width: 112%;
    flex-direction: column;
    @media (max-width: 910px) {
      display: flex;
    }

    .card-details {
      width: 700px;
      height: auto;
      max-width: 700px;
      background-color: white;
      border-radius: 5px;

      @media (max-width: 1799px) {
        margin: 0 30px;
      }
      @media (max-width: 1320px) {
        width: 600px;
        margin: 0;
      }
      @media(max-width: 910px) {
        width: 100%;
        margin-top: 20px;
      }

      hr {
        border-color: #F2F2F2;
        margin-top: 0;
      }

      p {
        font-size: 14px;
        line-height: 22.68px;
        font-weight: bold;
        color: var(--text-default-color);
        margin: 0;
        @media(max-width: 769px) {
          margin: 0 -3px 0 -40px;
        }
      }

      .progresso {
        margin: 20px 0 0 5px;
      }

      .details-link {
        .details-header {
          .card-title {
            display: flex;
            justify-content: space-between;
            margin: 0;

            .arrow {
              cursor: pointer;
            }
          }
        }
      }

      .hide-options {
        display: none;
        animation: animation 0.3s;
      }

      .open .hide-options {
        display: block;
      }

      .open .arrow {
        animation: girar .2s;
        transform: rotate(180deg);
      }

      @keyframes girar {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(180deg);
        }
      }

      img {
        margin: 0 10px 0 0;
        width: 14px;
      }

      span {
        font-weight: normal;
        color: var(--text-default-color-light);
      }

      span.link {
        text-decoration: underline;
      }

      .card-options {
        display: block;

        p {
          cursor: pointer;
          border: 2px solid var(--color-button-primary);
          width: 10%;
          font-size: 10px;
          font-weight: 600;
          border-radius: 5px;
          color: var(--text-default-color-light);
          display: flex;
          height: 36px;
          justify-content: center;
          align-items: center;
          margin: 0 0 35px 12px;

          @media (max-width: 769px) {
            width: 80%;
            margin-top: 10px;
          }
        }
      }

      .card-informations {
        p {
          font-weight: normal;
          padding: 10px 15px 14px;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }

  .progres-bar {
    display: flex;
    align-items: center;

    @media(max-width: 769px) {
      margin: 3px 5px;
    }

    .circle-one {
      display: flex;
      align-items: center;

      svg {
        color: #E0E0E0;
      }
    }

    .circle-two {
      svg {
        color: #0A6238;
        margin: -40px 6px;
        @media(max-width: 769px) {
          margin: -40px 7px;
        }
      }
    }

    p.name {
      margin: 0 -60px;
      width: 1000px;
    }

    p {
      font-size: 12px;
      margin: 0 -10px 0 5px;
    }

    p.result {
      margin: -40px 0 0 -81px;
      padding: 0;
      font-size: 10px;
      text-align: center;

      @media(max-width: 768px) {
        margin: -41px 0 0 -80px;
      }
    }
  }

  .rotate {
    animation: rotate-keyframes 1s;
  }

  @keyframes rotate-keyframes {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }
}

//
.modal-container {
  display: flex;
  justify-content: center;

  @media (max-width: 769px) {
    margin: 20px auto;
    width: 85%;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.3rem;
    outline: 0;
    background-color: #fff;

    .modal-promotion {
      background-image: url(../assets/icons/bgGreen.png);
      border-radius: 5px;
      width: 1000px;
      height: auto;
      padding-top: 34px;
      padding-bottom: 20px;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;

      @media(max-width: 1030px) {
        width: 100%;
        height: 100%;
        max-width: 400px;
      }

      .modal-logo {
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
        @media(max-width: 768px) {
          width: 70%;
        }
      }

      img {
        width: 100%;
      }

      .title {
        text-align: center;
        color: #FFFFFF;
        font-family: "Roboto", Sans-serif;
        font-size: 25px;
        line-height: 1.2em;
        margin: 0 0 10px;
        @media(max-width: 1030px) {
          font-size: 20px;
          width: 75%;
        }

        span {
          color: #FCD64B;
        }
      }

      .subtitulo {
        text-align: center;
        font-size: 23px;
        font-weight: 600;
        margin: 0;
        color: white;
        background: #424242;
        padding: 0 8px;
        border-radius: 3px;
      }

      .subtitle {
        text-align: center;
        padding: 0 5px 0;

        @media(max-width: 768px) {
          display: flex;
        }
      }

      p {
        margin-top: 0.6rem;
        color: var(--text-default-color);

        &.lote-2 {
          margin: 5px 0px;
          font-weight: 700;
        }

        &.negrito {
          font-weight: 700;
        }
      }

      .video {
        iframe {
          width: 100%;
          height: 418px;

          @media(max-width: 450px) {
            width: 100%;
            height: 174.08px;
          }
          @media(max-width: 768px) {
            width: 100%;
            height: 210px;
          }
        }
      }

      .content-modal {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 80px 0;
        align-items: center;

        @media(max-width: 1030px) {
          display: none;
        }

        .modal-logo {
          width: 300px;
        }

        .title-modal {
          text-align: center;
          color: #FFFFFF;
          font-family: "Roboto", Sans-serif;
          font-size: 25px;
          line-height: 1.2em;
          margin: 0 0 20px;
          width: 530px;
          padding: 0;
        }

        .warn-box {
          width: 300px;
          background: #ffeef1;
          border: 2px solid #cd201f;
          height: auto;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px;

          .contador {
            color: #4e4e4e;
          }

          p {
            color: #EB5757;
            text-align: center;
            width: 260px;
            font-size: 17px;
            font-weight: 600;
            margin: 0;
          }
        }

        .card-align {
          display: flex;
          gap: 43px;
          margin-top: 44px;
        }
      }

      .content-modal-mobile {
        display: none;

        @media(max-width: 1030px) {
          display: block;
        }
      }

      .card-star {
        background: #2F80ED;
        height: 25px;
        display: flex;
        width: 168px;
        border-radius: 100px 100px 0 0;
        margin: -25px auto 0;

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          color: white;
          font-weight: 600;
          padding-left: 2px;

          img {
            width: 15px;
          }
        }

        @media(max-width: 768px) {
          display: none;
        }
      }

      .contrast {
        background: #ff7600;
        height: 28px;
        text-align: center;
        position: absolute;
        width: 290px;
        margin: -17px 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        p {
          margin: 3px 0;
          color: #fff;
          font-size: 15px;
          font-weight: 600;
        }
      }

      .card-info {
        border-radius: 5px;
        background: #ffffff;
        width: 290px;
        height: auto;
        padding-top: 8px;
        @media(max-width: 768px) {
          margin: 0 auto 36px;
        }

        .content-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 8px;

          @media(max-width: 769px) {
            color: #333;
          }

          .card-title {
            display: flex;
            gap: 14px;
            align-items: center;
            margin: 0;

            img {
              max-width: 40px;
              max-height: 40px;
            }

            p {
              color: #333;
              display: flex;
              flex-direction: column;
              font-size: 32px;
              font-weight: 600;
              margin: 0;

              span {
                font-size: 15px;
                margin: 0 0 -14px 0;
                font-weight: 500;
                color: #333;
              }
            }
          }

          .message-discount {
            text-align: center;
            margin-bottom: 10px;

            p {
              margin: 0;
              color: #ff7600;
              font-weight: 600;
              font-size: 18px;
            }

            a {
              color: #0022ff;
            }
          }

          .price-align {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              color: #333;
              margin: 0;
              text-align: center;
              font-size: 14px;

              .original-price {
                text-decoration: line-through;
              }
            }

            .sale {
              color: #EB5757;
              font-size: 15px;
              font-weight: 600;
              background: #ed435b7d;
              width: 72px;
              margin: 0 auto;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 7px;
            }
          }

          .sale-text {
            margin-top: 6px;
          }

          p.assinatura {
            color: #00DE74;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0;
          }

          p.date {
            color: #333;
            background: var(--text-default-color-light);
            margin-bottom: 0;
            width: 198px;
            height: 40px;
            font-size: 15px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          p.info {
            color: #03D472;
            font-size: 37px;
            font-weight: 700;
            margin: 0;

            span {
              font-weight: 500;
              padding-right: 7px;
              font-size: 12px;
              color: #333;
            }
          }
        }

        .text {
          p {
            color: #666;
            margin: 0;
            padding: 2px 0 0 10px;
            font-size: 13px;
            display: flex;
            align-items: baseline;

            img {
              margin-right: 2px;
              width: 12px;
              height: 6.5px;
            }

            span {
              font-size: 10px;
              margin-left: 4px;
            }
          }

          .iconConfirm {
            margin-right: 2px;
            width: 12px;
            height: 6.5px;
          }

          .iconCancel {
            margin-right: 6px;
            width: 7px;
            height: 7px;
            margin-left: 1px;
          }

          a {
            color: #666;
            margin: 0;
            padding: 2px 0 0 10px;
            font-size: 13px;
            display: flex;
            align-items: baseline;

            .iconConfirm {
              margin-right: 2px;
              width: 12px;
              height: 6.5px;
            }
          }

          .checked {
            font-weight: 700;
          }
        }

        .button-card {
          display: flex;
          justify-content: center;
          margin-top: 41px;
        }

        .button {
          display: flex;
          justify-content: center;
        }

        .button button {
          background-color: #E4ECF7;
          width: 157px;
          height: 36px;
          color: #505780;
          font-weight: 600;
          border: none;
          border-radius: 6px;
          cursor: pointer;
        }

        .button-buy {
          display: flex;
          justify-content: center;

          .normal {
            background-color: #FCD64B;
            width: 157px;
            height: 36px;
            color: var(--text-default-color-light);
            font-weight: 600;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            margin: 37px;
          }

          .premium {
            box-shadow: 0 4px 0 0 #038749;
            background-color: #03D472;
            width: 200px;
            height: 40px;
            color: #fff;
            font-weight: 600;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            margin: 10px;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .premium:hover {
            transition: 2s;
            background-color: #129d5c;
          }

          .button-card button {
            background-color: #E4ECF7;
            width: 157px;
            height: 36px;
            color: #505780;
            font-weight: 600;
            border: none;
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }

      .close-modal {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        @media(max-width: 769px) {
          margin-top: 0;
        }

        p.close-button {
          cursor: pointer;
          color: #fff;
          text-decoration: underline;
        }

        @media(max-width: 769px) {
          display: none;
        }

        &.closeMobile {
          display: none;
          @media(max-width: 769px) {
            display: flex;
          }
        }
      }
    }
  }

  .modal {
    @media(max-width: 430px) {
      overflow: hidden !important;
    }
  }
}

.signatureModal {
  @media(max-width: 430px) {
    overflow: hidden;
    width: 100%;
  }

  .modal-content {
    @media(max-width: 1030px) {
      width: 100%;

      .modal-promotion {
        max-width: 100%;
      }
    }
  }
}

.modal-doubt {
  width: 100%;
  height: 619px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;

  @media(max-width: 769px) {
    width: 100%;
    height: auto;
    margin: 20px auto;
    padding-top: 60px;
  }

  .doubt {
    h1 {
      color: var(--text-default-color);
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      padding-bottom: 10px;
      @media(max-width: 769px) {
        margin: -35px 0 20px 0;
      }
    }

    p {
      margin-bottom: 6px;
      font-size: 14px;
      color: #000000;
      @media(max-width: 769px) {
        text-align: center;
      }
    }

    p.red {
      margin-bottom: 11px;
      color: #EB5757;
      text-align: center;
      @media(max-width: 769px) {
        text-align: center;
      }
    }

    span {
      font-weight: 300;
    }
  }

  .text {
    p.subject {
      color: #000;
      font-weight: 600;
      @media(max-width: 769px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .doubtComponent {
      height: 380px;

      @media(max-width: 768px) {
        height: 100%;
      }
    }

    input {
      overflow: visible;
      border: 1px solid #bdbcbc;
      height: 23px;
    }

    .ql-picker-label {
      svg {
        padding: 6px;
        margin: -7px -9px 0;
      }
    }

    .ql-picker-options {
      svg {
        padding: 5px;
        margin: -6px -9px;
      }
    }

    .ql-editor {
      height: 100%;
      @media(max-width: 768px) {
        height: 250px;
      }
    }
  }

  .button-doubt {
    position: relative;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    @media(max-width: 769px) {
      width: 100%;
      margin: 14px 0 0;
    }

    button {
      width: 181px;
      height: 36px;
      border: none;
      border-radius: 6px;
      background-color: #FCD64B;
      color: var(--text-default-color-light);
      font-weight: 600;
      font-size: 13px;
    }
  }

  .button-doubt .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .button-doubt .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .button-doubt:hover .tooltiptext {
    visibility: visible;
  }
}

.live-group {
  display: flex;
  flex-direction: column;
  @media(max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }

  h1 {
    font-weight: 700;
    font-size: 28px;
    @media(max-width: 768px) {
      text-align: center;
    }
  }

  p {
    color: var(--text-default-color-light);
    font-weight: 400;
    font-size: 14px;
    margin-left: 18px;
    @media(max-width: 768px) {
      text-align: center;
    }
  }
}

.live-card {
  width: 100%;
  height: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 768px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .group-card-live {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    align-items: center;
    @media(max-width: 768px) {
      flex-direction: column;
    }
  }

  .name {
    margin: 0;
    color: var(--text-default-color);
    font-weight: 300;
    font-size: 16px;
    @media(max-width: 768px) {
      margin-top: 23px;
    }
  }

  .date {
    font-size: 16px;
    color: #666;
    padding-right: 60px;
    margin: 0;
    font-weight: 400;
    @media(max-width: 768px) {
      margin-left: 0;
      margin-bottom: 17px;
      padding: 0;
    }
  }


  .live-button {
    width: 170px;
    height: 46px;
    background-color: #FCD64B;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-default-color-light);
    font-weight: 600;
    font-size: 14px;
  }

  .live-block {
    width: 170px;
    height: 46px;
    background-color: #F2F2F2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-default-color-light);
    font-weight: 600;
    font-size: 14px;
  }

  .live-past {
    width: 170px;
    height: 46px;
    background-color: #FDE796;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-default-color-light);
    font-weight: 600;
    font-size: 14px;
  }
}

.live-mobile {
  @media (max-width: 768px) {
    .filter-box .card-body {
      padding-top: 0;
      padding-bottom: 18px;
    }
  }
}

.mobile-live {
  @media (max-width: 768px) {
    .short-mobile {
      display: flex;
      margin: 0;
      width: 100%;
      justify-content: center;
    }
  }
}

.normal-text {
  text-align: center;
  width: 60%;
  margin: 0 auto 15px;

  @media(max-width: 768px) {
    width: 95%;
  }
}

.filter-space {
  display: contents;
  @media(max-width: 1315px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 -15px;
  }

  .card-secondary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}


.containerFilter {
  margin: 1.25rem 0 1.438rem 0;
  -webkit-transition: 0.5s ease-in;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;

  background: white;
  border-radius: 1.25rem;
  box-shadow: 5px 5px 15px 0px rgba(44, 44, 44, 0.10);

  .mainFilter {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 1.563rem 2.188rem;

    > div.filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media(max-width: 991px) {
        flex-wrap: wrap;
      }

      @media(max-width: 769px) {
        flex-direction: column;
      }
    }
  }

}

.q-site-font-loaded body {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif
}

.body-m-b {
  margin-bottom: 100px;
}

.q-icon {
  opacity: 1;
  font-family: qicons, serif
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

@media(min-width: 768px) {
  select.form-control {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%22222222'><polygon points='0,0 100,0 50,50'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) calc(50% + 2px);
    background-size: 8px
  }

  .badge-radio, .checkbox, .radio {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 42px;
    cursor: pointer
  }

  @media(min-width: 768px) {
    .badge-radio, .checkbox, .radio {
      padding-left: 30px
    }
  }

  .badge-radio input, .checkbox input, .radio input {
    display: none
  }

  .badge-radio input:disabled ~ span, .checkbox input:disabled ~ span, .radio input:disabled ~ span {
    cursor: default;
    opacity: .5
  }

  .badge-radio span, .checkbox span, .radio span {
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px
  }
}

@media(min-width: 768px) {
  .badge-radio span, .checkbox span, .radio span {
    font-size: 12px
  }
}


@media(min-width: 768px) {
  .badge-radio span:after, .radio span:after {
    left: 4px;
    width: 9px;
    height: 9px;
    border-radius: 4.5px
  }
}

.badge-radio input:checked ~ span:before, .radio input:checked ~ span:before {
  border-color: #bce3ec;
  background-color: #edf8ff
}

.badge-radio input:checked ~ span:after, .radio input:checked ~ span:after {
  display: block
}

.checkbox span:after, .checkbox span:before {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 20px;
  display: inline-block;
  content: ""
}

.checkbox span:before {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;

}

@media(min-width: 768px) {
  .checkbox span:before {
    width: 20px;
    height: 20px
  }
}

.checkbox span:after {
  content: '';

  display: none;

  left: 0;

  width: 24px;
  height: 22px;


  text-align: center;
  font-size: 1.063rem;
  pointer-events: none;

  background-image: url('../assets/icons/icon-checkbox.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 20px 15px 0;
  background-color: var(--color-primary);
}

@media(min-width: 768px) {
  .checkbox span:after {
    width: 20px;
    height: 20px;
    font-size: 13px;
    line-height: 21px
  }
}

.checkbox span:hover:before {
  border-color: rgba(0, 0, 0, .18)
}

.checkbox input:checked ~ span:before {
  border-color: #bce3ec;
  background-color: #edf8ff
}

.checkbox input:checked ~ span:after {
  display: inline-block;
  border-radius: 5px;
}


.checkbox.disabled label, .disabled.badge-radio label, .radio.disabled label, fieldset[disabled] .badge-radio label, fieldset[disabled] .checkbox label, fieldset[disabled] .radio label, fieldset[disabled] input[type=checkbox], fieldset[disabled] input[type=radio], input[type=checkbox].disabled, input[type=checkbox][disabled], input[type=radio].disabled, input[type=radio][disabled] {
  cursor: not-allowed
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9
;
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-left: 4px solid rgba(0, 0, 0, 0)
}

.dropdown, .dropup {
  position: relative
}


.dropdown-menu {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, .05);
  font-size: 14px
}

.dropdown-menu.pull-right {
  right: 0 !important;
  left: auto !important
}

.dropdown-menu .divider {
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .1)
}

.dropdown-menu > li > a {
  display: -webkit-flex;
  display: flex;
  clear: both;
  -webkit-align-items: center;
  align-items: center;
  padding: 7.5px 20px;
  white-space: nowrap;
  color: #495057;
  font-weight: 400;
  line-height: 1.618
}

@media(max-width: 768px) {
  .dropdown-menu > li > a {
    padding: 12.5px 20px
  }
}

.dropdown-menu > li > a i + span {
  margin-left: .5em;
  line-height: 1
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  text-decoration: none;
  color: #343a40;
  background-color: #f1f3f5
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  text-decoration: none;
  color: #35707d;
  outline: 0;
  background-color: #edf8ff
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  color: #e9ecef
}

.dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  background-image: none
}

.open > .dropdown-menu {
  display: block
}

.hide > .dropdown-menu {
  display: none
}


.open > a {
  outline: 0
}

.select-search__input .spinner-border {
  color: var(--grey-500);
  width: 15px;
  opacity: 0.5;
  height: 15px;
  margin-left: 10px;
  border-width: 2px;
}

.form-group {
  margin-bottom: 1.25rem;
}

.select-search-input {
  display: flex;
  box-shadow: 0 1px 3px 0 #00000036;
  border-width: 2px;
  width: 300px;
  height: 36px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #FFFFFF;
  padding: 7px;
  cursor: default;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 15px;
    height: 35px;
  }
}

.select-search-input:hover {
  box-shadow: 0 2px 6px 0 #00000063;
  transition: .9s;
}

.q-icon {
  display: inline-block;
  -webkit-transform: translate(0);
  transform: translate(0);
  text-rendering: auto;
  font: 400 normal 400 14px/1;
  font-size: 25px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased
}


.q-icon-search {
  svg {
    margin-bottom: 7px;
    width: 18px;
    height: 18px;
  }
}

.q-icon-times:before {
  content: "×"
}


.q-icon {
  font-weight: 400 !important;
  font-style: normal

}


.q-select-input {
  position: relative
}

.q-select-input .dropdown-toggle {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  padding-right: 35px;
  text-align: left;
  outline: none
}

.q-select-input .dropdown-toggle.input-lg .caret {
  top: 18px
}

.q-placeholder {
  color: #9d9b9b;
  max-width: 60vw;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.q-select-input .dropdown-toggle .caret {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #000
}

@media(min-width: 768px) {
  .q-select-input .dropdown-toggle .caret {
    top: 14px
  }
}

.q-count {
  color: #000;
  font-size: 0.625rem;

  margin-left: auto;
  max-width: 80px;
  padding-left: 1.5rem;

  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.q-select-input .dropdown-menu {
  position: static;
  z-index: 10050;

  top: auto;
  left: auto;

  width: auto;
  min-width: 100%;
  max-width: 100%;

  padding: 15px 10px;
  border-width: 0;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: none;
}

@media(min-width: 768px) {
  .q-select-input .dropdown-menu {
    margin-top: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 340px;
    max-width: 340px;
    padding-bottom: 20px;
    border-width: 1px;
    border-radius: 18px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .12)
  }
}

.q-select-input .dropdown-menu .q-search-input {
  margin: 23px 12px;
  position: relative;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 2px 10px -7px #000;
  border-radius: 12px;

  @media (max-width: 769px) {
    margin: 1.25rem 0;
  }

}

@media(min-width: 768px) {
  .q-select-input .dropdown-menu .q-search-input {
    background-color: var(--grey-200)
  }
}

.q-select-input .dropdown-menu .q-search-input > input {
  height: 35px;
  padding-right: 50px;
  border: none;
  border-radius: 12px;
  text-indent: 20%;
  background-color: rgba(0, 0, 0, 0)
}

@media(min-width: 768px) {
  .q-select-input .dropdown-menu .q-search-input > input {
    background-color: var(--grey-200);
  }
}

.q-select-input .dropdown-menu .q-search-input > .q-icon {
  display: inline-block;
  padding: 0 2px
}

.q-select-input .dropdown-menu .q-search-input > .q-icon {
  position: absolute;
  top: 0;
  left: 10px
}

.q-select-input .dropdown-menu .q-search-input .q-clear-btn {
  position: absolute;
  top: 0;
  right: 10px
}

.q-select-input .dropdown-menu .q-search-input .q-clear-btn {
  display: none;

  right: -70px;
  margin: -4px;
  padding: 0;
  border: none;
  outline: none;
  -webkit-appearance: none;

  color: #6d767e;
  background: rgba(0, 0, 0, 0);

  cursor: pointer;
}

.q-select-input .dropdown-menu .q-search-input .q-clear-btn:hover {
  color: #343a40
}

.q-select-input .dropdown-menu .q-search-input:hover .q-clear-btn {
  display: inline-block
}

.q-select-input .dropdown-menu .q-hidden-inputs {
  display: none
}

.q-select-input .dropdown-menu .q-options {
  overflow: visible;
  overflow-y: auto;

  width: 95%;
  max-height: 320px;
  margin: 0;
  padding: 0;

  list-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media(max-height: 823px) {
    max-height: 400px;
  }

  @media(max-height: 667px) {
    max-height: 320px;
  }

  @media (max-height: 640px) {
    max-height: 300px;
  }

  @media(max-height: 568px) {
    max-height: 250px;
  }

}

//scroll bar
.q-select-input .dropdown-menu .q-options {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8A9099;
    border-radius: 4px;
  }
}


.q-select-input .dropdown-menu .q-options .q-empty {
  padding: 15px 10px;
  text-align: center;
  font-size: 12px
}

.q-select-input .dropdown-menu .q-options li {
  width: 100%;
  min-width: 100%;
  padding: 0 10px 0 26px;
  outline: none;

  @media(max-height: 640px) {
    padding: 0 10px 0 10px;
  }

}

.q-select-input .dropdown-menu .q-options li:focus, .q-select-input .dropdown-menu .q-options li:hover {
  background-color: #f1f3f5
}

.q-select-input .dropdown-menu .q-options li:focus label, .q-select-input .dropdown-menu .q-options li:hover label {
  color: #343a40
}

.q-select-input .dropdown-menu .q-options li label {
  width: 100%;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  cursor: pointer;
  word-break: break-all;
  word-break: break-word;
  color: #5b6570
}

@media(min-width: 768px) {
  .q-select-input .dropdown-menu .q-options li label {
    padding-top: 10px;
    padding-bottom: 10px
  }
}

.q-select-input .dropdown-menu .q-options li.q-select-heading {
  padding: 10px 15px 10px 46px;
  pointer-events: none;
  color: #56606a;
  border-bottom: 1px solid rgba(8, 8, 8, .1);
  font-size: 12px;
  font-weight: 900
}

@media(min-width: 768px) {
  .q-select-input .dropdown-menu .q-options li.q-select-heading {
    padding-left: 36px
  }
}

.q-select-input .dropdown-menu .q-options li.q-select-heading:hover {
  background-color: rgba(0, 0, 0, 0)
}

.q-select-input .dropdown-menu .q-dropdown-footer {
  display: none
}

.q-select-input-single .dropdown-menu li.selected, .q-select-input-single .dropdown-menu li.selected:hover {
  color: #35707d !important;
  background-color: #edf8ff
}

.q-select-input-single .dropdown-menu li.selected:hover span, .q-select-input-single .dropdown-menu li.selected span {
  color: #35707d !important
}

.q-select-input-single .dropdown-menu li .checkbox, .q-select-input-single .dropdown-menu li .radio {
  padding-right: 15px;
  padding-left: 15px
}

.q-select-input-single .dropdown-menu li .checkbox span:after, .q-select-input-single .dropdown-menu li .checkbox span:before, .q-select-input-single .dropdown-menu li .radio span:after, .q-select-input-single .dropdown-menu li .radio span:before {
  display: none
}

.q-select-input-single.q-select-input-mobile-modal .dropdown-menu li {
  padding-right: 15px;
  padding-left: 15px
}

@media(max-width: 768px) {
  .q-select-input-mobile-modal .dropdown-backdrop {
    display: none;
    pointer-events: none
  }

  .q-select-input-mobile-modal .dropdown-menu {
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 15px;
    background-color: rgba(33, 37, 41, .5)
  }

  .q-select-input-mobile-modal .dropdown-menu .q-dropdown-content {
    position: relative;
    width: 100%;
    max-width: 92vw;
    max-height: 100%;
    margin: 0 auto;
    padding-bottom: 60px;
    border-radius: 3px;
    background-color: #fff
  }

  .q-select-input-mobile-modal .dropdown-menu .q-search-input > input {
    height: 45px;
    padding-right: 70px;
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    border-radius: 30px;
  }

  .q-select-input-mobile-modal .dropdown-menu .q-search-input > .q-icon {
    z-index: 10;
    top: 14px;
    right: 16px;
    font-size: 16px
  }

  .q-select-input-mobile-modal .dropdown-menu .q-search-input .q-clear-btn {
    z-index: 10;
    top: 10px;
    left: 42px;
    font-size: 20px
  }

  .q-select-input-mobile-modal .dropdown-menu .q-options {
    max-height: calc(80vh - 150px)
  }

  .q-select-input-mobile-modal .dropdown-menu .q-options .checkbox, .q-select-input-mobile-modal .dropdown-menu .q-options .radio {
    padding-top: 15px;
    padding-bottom: 15px
  }

  .q-select-input-mobile-modal .dropdown-menu .q-dropdown-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    text-align: right;
    box-shadow: 0 -2px 10px -7px #000
  }
}

.selectSearch {
  width: 23%;

  > button {
    width: 98px;
    margin-left: auto;
  }

  @media(max-width: 991px) {
    width: 38%;
  }

  .checkBox {

    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 42px;
    cursor: pointer;

    input {
      display: none;
    }

    span {
      text-transform: capitalize;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      line-height: 22px;
      color: var(--grey-text-light);

      &:before, &:after {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        display: inline-block;
        content: "";
      }

      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        background-image: url('../assets/icons/icon-cross.svg');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 10px 10px;
        font-weight: 500;
        font-size: 1rem;
        background-color: #F8F8F8;

        @media(min-width: 768px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    @media(min-width: 768px) {
      padding-left: 30px;
    }

  }
}

.group-button {
  margin-bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;

  @media (min-width: 1800px) {
    width: 86.4%;
  }
}

.group-form {
  justify-content: space-between;
  display: flex;
  margin-left: 0;
  width: 108%;
  margin-right: 0;
}

.input-group {
  margin-left: 15px;
  margin-right: 25px;
}

.avanced-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}

.group-fixed {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #F8F8F8;
  box-shadow: 0 0 30px 0 #00000073;
}

.button-fix {
  margin-top: 15px;
  font-size: 15px;
  border-radius: 8px;
  justify-content: center;
}

.anotation {
  width: 744px;
  height: 392px;
  border: 1px solid #EDF2F7;
  border-radius: 5px;

  &.error {
    animation: error .7s;
    box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.55);
  }

  @keyframes error {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media(max-width: 769px) {
    width: 100%;
  }
  @media(min-width: 770px) {
    .ql-toolbar.ql-snow {
      border: none;
      width: 70%;
      margin: 10px auto;
      box-shadow: 0 0 8px 0 #00000026;
      padding: 7px 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ql-formats {
        display: inline-block;

        button {
          background: none;
          border: none;
          cursor: pointer;
          display: inline-block;
          float: left;
          height: 30px;
          padding: 3px 5px;
          width: 25px;
        }

        svg {
          float: left;
          height: 100%;
        }
      }

      .ql-container.ql-snow {
        border-top: 1px solid #EDF2F7;
      }
    }
    .ql-container.ql-snow {
      border: none;
      font-size: 16px;
      height: 290px;
    }
  }
}

.div-change-cc {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  .alterar-cartao {
    cursor: pointer;
    color: #198B54;
    font-weight: 300;
  }
}

.table-responsive {
  .table {
    tr {
      th {
        color: #5a5e5c;
      }

      td {
        color: #7D8380;

        span {
          text-transform: capitalize;
        }
      }
    }
  }
}

.ql-editor {
  background-color: #ffffff;
}


.rs-picker-daterange-content {
  .rs-picker-daterange-header {
    display: none !important;
  }
}

.rs-picker {
  width: 200px;
  @media(max-width: 769px) {
    width: 100%;
  }
}

.rs-picker-toolbar {
  .rs-stack-item {
    .rs-picker-toolbar-ranges {
      display: none !important;
    }
  }
}


.c-default-layout.dark-mode, .dark-mode {

  .c-body {
    background-color: var(--dark-black-800);

    .backtohome {
      color: var(--dark-text);
    }
  }

  .c-header {
    background-color: var(--dark-black-600);

    .c-header-div {
      background-color: var(--dark-black-500);
    }
  }

  .c-sidebar {
    .c-sidebar-brand {
      background-color: var(--dark-black-600);
    }

    .c-sidebar-nav {
      background-color: var(--dark-black-600);

      .signature {
        background-color: var(--dark-black-500);

        button {
          color: var(--dark-text);
        }
      }
    }

    .c-sidebar-minimizer {
      background-color: var(--dark-black-500);
    }
  }

  .card-boxnotifications {
    background-color: var(--dark-black-500);
  }

  .btn {
    color: var(--dark-text);
    border: 1px solid var(--dark-text);
    background: transparent;

    &:hover {
      color: var(--dark-text);
    }

    label {
      color: var(--dark-text);
    }
  }

  .header-store {
    @media(max-width: 769px) {
      background: none;
      border: 1px solid var(--dark-text);
      color: var(--dark-text);
    }
  }

  .container-comments {
    .ql-snow .ql-stroke {
      color: #ffffff;
      stroke: #ffffff;
    }

    .quill {
      &.ql-container {
        .ql-editor {
          * {
            color: var(--dark-black-500)
          }
        }
      }
    }

    .ql-snow .ql-picker-options .ql-picker-item {
      color: var(--dark-black-500)
    }
  }
}

.modalObs {
  .ql-tooltip {
    display: none;
  }
}

.table-track {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;

  th {
    background-color: #198b54;
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 10px;
  }

  td {
    border: 1px solid #ddd;
    padding: 10px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .status-entregue {
    color: green;
  }

  .status-transito {
    color: orange;
  }
}
