// Variable overrides
:root {
  --blue-800: #091e42;
  --blue-hover: #0e2dcf;
  --blue-btn: #304FFD;

  --purple-btn: #f0ecfc;
  --orange-default: #FB8209;

  --red-300: #F05C54;
  --red-500: #dc3545;

  --grey-200: #F8F8F8;
  --grey-300: #E8E9EB;
  --grey-400: #7D8380;
  --grey-500: #8A9099;

  --grey-text-light: #595F69;
  --grey-text-dark: #3F434A;

  --red-alternative-wrong-300: #FFE0DE;
  --red-alternative-wrong-500: #FD7972;

  --green-alternative-correct-300: rgba(65, 184, 131, 0.33);
  --green-alternative-correct-500: #20A144;
  --green-btn-1:#59C591;
  --green-btn-2:#198b54;

  --dark-black-500: #33363D;
  --dark-black-600: #23262B;
  --dark-black-800: #1D1E24;

  --dark-red-alternative-wrong-500: #F05C54;

  --dark-text: #bdc1c6;
  --dark-text-light: #ffffff;

  //--color-primary: #198b54;
  //--color-secondary: #0a6238;
  //--color-third: #084d2c;
  //--color-button-primary: #fde796;
  //--color-button-primary-text:#4f5d73;

  --text-default-color:#29272E;
  --text-default-color-light: #3c3c3c;

  --dark-purple-300 {
    color: #8ab4f8;
  }
}
.ft-size12 {
  font-size: 0.75rem;
  line-height: 1.313rem;
}

.ft-size14 {
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.ft-size15 {
  font-size: 0.938rem;
  line-height: 1.313rem;
}

.ft-size16 {
  font-size: 1rem;
  line-height: 1.313rem;
}

.ft-size18 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.ft-size20 {
  font-size: 1.25rem;
  line-height: 1.688rem;
}

.ft-size22 {
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.ft-size24 {
  font-size: 1.5rem;
  line-height: 2.063rem;
}

.ft-size36 {
  font-size: 2.25rem;
}
